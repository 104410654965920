@charset "UTF-8";


@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes vibrate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes vibrate02 {
  0% {
    transform: rotate(0deg) scale(1.2);
  }
  5% {
    transform: rotate(15deg) scale(1.2);
  }
  10% {
    transform: rotate(-15deg) scale(1.2);
  }
  15% {
    transform: rotate(15deg) scale(1.2);
  }
  20% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}
@keyframes burger-show {
  0% {
    right: -50%;
  }
  100% {
    right: 0%;
  }
}
@keyframes header-show {
  0% {
    opacity: 0;
    top: -100px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
a:hover {
  outline: none;
}
a:active {
  outline: none;
}
a:focus {
  outline: none;
}

@media screen and (min-width: 767px) { /* =================================
  hover
================================= */
  a {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .widelink {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    cursor: pointer;
  }
  .widelink .widelink__cover {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__cover::before {
    content: "詳しく見る";
    font-size: 1.4rem;
    color: #fff;
    letter-spacing: 0.06em;
    line-height: 1;
    text-align: center;
    opacity: 0;
    padding: 10px 0;
    width: 55%;
    border: 1px solid #fff;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity, top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__cover::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background-color: rgba(31, 31, 31, 0.5);
    color: white;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__menu {
    position: relative;
    overflow: hidden;
    display: block;
  }
  .widelink .widelink__menu::before {
    content: "詳しく見る";
    font-size: 1.3rem;
    font-weight: 700;
    color: #fff;
    line-height: 1;
    text-align: center;
    opacity: 0;
    padding: 15px 9%;
    border: 1px solid #fff;
    border-radius: 50px;
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 6;
    transition-property: opacity, bottom;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__article {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__article::before {
    content: "";
    display: inline-block;
    width: 130px;
    height: 24px;
    background: url(../img/link_more.png) no-repeat center center;
    opacity: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity, top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__article::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background-color: rgba(0, 84, 142, 0.7);
    color: white;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink:hover a {
    text-decoration: none;
  }
  .widelink:hover .widelink__cover::before, .widelink:hover .widelink__article::before {
    opacity: 1;
    top: 50%;
  }
  .widelink:hover .widelink__cover::after, .widelink:hover .widelink__article::after {
    opacity: 1;
  }
  .widelink:hover .widelink__menu::before {
    opacity: 1;
    bottom: 10%;
  }
  .hoverBg .widelink__cover::before {
    content: none;
  }
  .hoverBg .widelink__cover::after {
    content: none;
  }
  .hoverBg:hover {
    background: #F5F8FF;
  }
}
/*=================================
  button
=================================*/
.btnWrap {
  margin: 20px auto 30px;
  text-align: center;
}
.btnWrap.center {
  text-align: center;
}
.btnWrap.right {
  text-align: right;
}
.btnWrap > * + * {
  margin-top: 9px;
}

.btn--blue {
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: #304A85;
  padding: 15px;
}

.btn--gray {
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1.05;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: #333333;
  box-shadow: 0 5px 0 #202427;
  display: block;
  padding: 28px 30px;
  position: relative;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .btn--gray {
    padding: 2.4158125915vw 16.4714494876vw;
  }
}
.btn--gray::after {
  content: "";
  width: 12px;
  height: 19px;
  display: inline-block;
  vertical-align: middle;
  background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.btn--blueShadow {
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  background: #3da7f0;
  box-shadow: 0 3px 0 #304A85;
  border-radius: 4px;
  padding: 23px 22px;
  text-decoration: none;
}

.btn--ghost {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  padding: 15px 58px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.3);
  position: relative;
}
.btn--ghost::after {
  content: "";
  width: 9px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
}

.btn--border {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1;
  color: #304A85;
  border: 3px solid #304A85;
  background: #fff;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 0 auto;
  padding: 16px 1%;
}
.btn--border::before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 17px;
  background: url(../img/btn_arrow.png) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
}

@media screen and (min-width: 767px) {
  /*=================================
    button
  =================================*/
  .btnWrap {
    display: flex;
    justify-content: center;
  }
  .btnWrap > * + * {
    margin: 0 20px;
  }
  .btn--blue {
    font-size: 2.2rem;
    padding: 23px 37px;
  }
  .btn--blue:hover {
    opacity: 0.9;
  }
  .btn--gray {
    font-size: 2rem;
    padding: 33px 225px;
  }
  .btn--gray::after {
    width: 14px;
    height: 24px;
    right: 16px;
  }
  .btn--gray:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
  .btn--blueShadow {
    font-size: 2.2rem;
    box-shadow: 0 5px 0 #304A85;
    padding: 24px 30px;
    transition: none;
  }
  .btn--blueShadow:hover {
    transform: translateY(5px);
    box-shadow: none;
  }
  .btn--ghost {
    font-weight: 700;
    background: transparent;
  }
  .btn--ghost:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  .btn--border {
    font-size: 2.2rem;
    border-width: 4px;
    padding: 20px 66px;
    margin: 0;
    display: inline-block;
  }
  .btn--border::before {
    width: 24px;
    height: 24px;
    margin: 0 6px 3px 0;
  }
  .btn--border:hover {
    background: #f0f8fe;
  }
}
/*=================================
  title
=================================*/
.ttl--base {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 50px;
}
.ttl--base::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  margin: -5px 15px 0 0;
}
.ttl--base:not(.left) {
  text-align: center;
}
.ttl--base.white {
  color: #fff;
}
.ttl--base.white::before {
  background: #fff;
}
.ttl--base + .ttl--text {
  margin-top: -40px;
  margin-bottom: 50px;
}

.ttl--page {
  padding: 30px 1% 0;
  margin-bottom: 30px;
}
.ttl--page__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.ttl--page .ttl {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1.33333;
  padding-left: 25px;
  position: relative;
}
.ttl--page .ttl::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  position: absolute;
  top: 25px;
  left: 0;
}
.ttl--page .text {
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 7px;
}

@media screen and (max-width: 767px) {
  /*=================================
    title
  =================================*/
  .ttl--base {
    font-size: 2rem;
    line-height: 1.55;
    margin-bottom: 20px;
  }
  .ttl--base + .ttl--text {
    margin-top: -10px;
    margin-bottom: 20px;
  }
  .ttl--page {
    padding: 0 5.1282051282%;
    margin-bottom: 30px;
  }
  .ttl--page .ttl {
    font-size: 2.4rem;
    padding-left: 15px;
  }
  .ttl--page .ttl::before {
    width: 8px;
    height: 8px;
    top: 13px;
  }
  .ttl--page .text {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.714285;
  }
}
/*=================================
  tab
=================================*/
.tabMenu {
  display: flex;
}
.tabMenu__item {
  font-size: 2.2rem;
  line-height: 1.363636;
  font-weight: 500;
  color: #222;
  background: #F3F3F3;
  padding: 30px 24px;
  text-align: center;
  width: 100%;
}
.tabMenu__item:not(.active) {
  cursor: pointer;
}
.tabMenu__item.active {
  background: #304A85;
  color: #fff;
}
@media screen and (min-width: 767px) {
  .tabMenu__item:hover:not(.active) {
    background: #F5F8FF;
  }
}

.tabItem {
  background: #fff;
  min-height: 190px;
}
.tabItem .tabList {
  display: none;
}
.tabItem .tabList.show {
  display: block !important;
}

@media screen and (max-width: 767px) {
  /*=================================
    tab
  =================================*/
  .tabMenu__item {
    font-size: 1.6rem;
    padding: 27px 4.358974359%;
  }
  .tabItem {
    min-height: inherit;
  }
}
/*=================================
  accordion
=================================*/
.js-accordion__btn {
  position: relative;
  cursor: pointer;
}

.js-accordion__item {
  display: none;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
    accordion
  =================================*/
}
/*=================================
  table
=================================*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 30px 0 50px;
  width: 100%;
  border: 1px solid #E3E3E3;
}
table caption {
  font-size: 1.4rem;
  margin: 20px 0;
  text-align: left;
  caption-side: bottom;
}
table thead th, table thead td {
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background: #304A85;
}
table th {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  background: #F5F8FF;
}
@media screen and (min-width: 767px) {
  table th {
    width: 250px;
  }
}
table td {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
}
@media screen and (min-width: 767px) {
  table td {
    width: calc(100% - 250px);
  }
}
table th, table td {
  padding: 19px 25px;
  border: 1px solid #E3E3E3;
  vertical-align: top;
}
table.scroll {
  white-space: nowrap;
  overflow: auto;
  display: block;
  border: none;
}
table.scroll ::-webkit-scrollbar {
  height: 5px;
}
table.scroll ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
table.scroll ::-webkit-scrollbar-thumb {
  background: #bcbcbc;
}

.scrollAnnounce {
  display: none;
}

@media screen and (max-width: 767px) {
  /*=================================
    table
  =================================*/
  table {
    margin: 20px 0 40px;
    /* スクロールのつまみ部分の設定 */
  }
  table:not(.noscroll) {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    border: none;
  }
  table caption {
    font-size: 1.5rem;
    margin: 15px 0;
  }
  table th, table td {
    padding: 14px 20px;
  }
  table::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  table::-webkit-scrollbar-track {
    border-radius: none;
    background: #d6d6d6;
  }
  table::-webkit-scrollbar-thumb {
    background: #FECB3F;
  }
  .scrollAnnounce {
    display: block;
    text-align: center;
  }
  .scrollAnnounce .text {
    font-size: 1.3rem;
    font-weight: 600;
    color: #fff;
    background: #304A85;
    padding: 3px 6px;
    position: relative;
  }
  .scrollAnnounce .text::after {
    content: "";
    border-style: solid;
    border-width: 7px 6.5px 0 6.5px;
    border-color: #304A85 transparent transparent transparent;
    display: inline-block;
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
  }
}
/*=================================
  breadcrumb
=================================*/
.breadcrumb {
  margin-bottom: 20px;
}
.breadcrumb ul li {
  font-size: 1.4rem;
  line-height: 1;
  color: #B8B8B8;
  display: inline-block;
}
.breadcrumb ul li a {
  font-size: 1.4rem;
  line-height: 1;
  color: #222;
  text-decoration: none;
  margin-right: 18px;
}
@media screen and (min-width: 767px) {
  .breadcrumb ul li a:hover {
    color: #304A85;
    text-decoration: underline;
  }
}
.breadcrumb ul li a::after {
  content: "";
  width: 6px;
  height: 10px;
  background: url(../img/common/icon/icon_arrow_breadcrumb.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  margin-left: 18px;
}
.breadcrumb.foot {
  margin: 0;
  padding: 17px 1%;
  border-bottom: 1px solid #E1E1E1;
}
.breadcrumb.foot .inner-wrap {
  max-width: 1260px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
    breadcrumb
  =================================*/
  .breadcrumb ul li {
    font-size: 1rem;
    line-height: 1.5;
  }
  .breadcrumb ul li a {
    font-size: 1rem;
    line-height: 1.5;
    margin-right: 7px;
  }
  .breadcrumb ul li a::after {
    margin-left: 7px;
    margin-bottom: -1px;
  }
}
/*=================================
  pager
=================================*/
.pager {
  max-width: 1000px;
  margin-top: 80px;
}
.pagerList {
  display: flex;
  justify-content: center;
}
.pagerList__item {
  margin-right: 6px;
}
.pagerList__item a, .pagerList__item span {
  font-size: 1.5rem;
  line-height: 1;
  padding: 13px 16px;
  display: block;
}
.pagerList__item a {
  color: #304A85;
  text-decoration: none;
  background: #F5F8FF;
}
@media screen and (min-width: 767px) {
  .pagerList__item a:hover {
    color: #fff;
    background: #304A85;
  }
}
.pagerList__item span {
  color: #fff;
  background: #304A85;
}
.pager .wp-pagenavi {
  display: flex;
  justify-content: center;
}
.pager .wp-pagenavi .pages {
  font-size: 1.5rem;
  line-height: 1;
  padding: 13px 16px;
  margin-right: 6px;
  border: 1px solid #304A85;
}
.pager .wp-pagenavi .current {
  font-size: 1.5rem;
  line-height: 1;
  color: #fff;
  padding: 13px 16px;
  background: #304A85;
  display: block;
  text-decoration: none;
  border: 1px solid #304A85;
}
.pager .wp-pagenavi .page {
  font-size: 1.5rem;
  color: #304A85;
  line-height: 1;
  padding: 13px 16px;
  display: block;
  background: #F5F8FF;
  text-decoration: none;
  margin-left: 6px;
  border: 1px solid #F5F8FF;
}
@media screen and (min-width: 767px) {
  .pager .wp-pagenavi .page:hover {
    color: #fff;
    background: #304A85;
  }
}
.pager .wp-pagenavi .nextpostslink {
  font-size: 1.5rem;
  color: #304A85;
  line-height: 1;
  padding: 13px 16px;
  display: block;
  background: #fff;
  text-decoration: none;
  margin-left: 6px;
  border: 1px solid #304A85;
}
@media screen and (min-width: 767px) {
  .pager .wp-pagenavi .nextpostslink:hover {
    color: #fff;
    background: #304A85;
  }
}
.pager .wp-pagenavi .previouspostslink {
  font-size: 1.5rem;
  color: #304A85;
  line-height: 1;
  padding: 13px 16px;
  display: block;
  background: #fff;
  text-decoration: none;
  border: 1px solid #304A85;
}
@media screen and (min-width: 767px) {
  .pager .wp-pagenavi .previouspostslink:hover {
    color: #fff;
    background: #304A85;
  }
}

@media screen and (max-width: 767px) {
  /*=================================
    pager
  =================================*/
  .pager {
    display: block;
    text-align: center;
    margin-top: 40px;
  }
  .pagerList {
    justify-content: center;
  }
  .pagerList__item {
    margin-right: 8px;
  }
  .pagerList__item a, .pagerList__item span {
    padding: 11px 14px;
  }
  .pager .wp-pagenavi .pages {
    padding: 11px 14px;
    margin-right: 8px;
  }
  .pager .wp-pagenavi .current {
    padding: 11px 14px;
  }
  .pager .wp-pagenavi .page {
    padding: 11px 14px;
    margin-left: 8px;
  }
  .pager .wp-pagenavi .nextpostslink {
    padding: 11px 14px;
    margin-left: 8px;
  }
  .pager .wp-pagenavi .previouspostslink {
    padding: 11px 14px;
  }
}
/*=================================
  topPage
=================================*/
.home .secWrap {
  padding: 90px 1% 60px;
}
.home .secWrap__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.home .secWrap.reason { /* 選ばれる理由 */
  padding: 100px 0 64px calc((100% - 1140px) / 2);
  background: url(../img/top/bg_reason.svg) no-repeat right 63px top 142px;
  background-size: 300px auto;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .home .secWrap.reason {
    padding: 100px 0 64px 1%;
  }
}
.home .secWrap.reason .reason__inner {
  background: rgb(48, 74, 133);
  background: -moz-linear-gradient(95deg, rgba(48, 74, 133, 0.15) 0%, rgba(119, 98, 49, 0.15) 100%);
  background: -webkit-linear-gradient(95deg, rgba(48, 74, 133, 0.15) 0%, rgba(119, 98, 49, 0.15) 100%);
  background: linear-gradient(95deg, rgba(48, 74, 133, 0.15) 0%, rgba(119, 98, 49, 0.15) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#304a85",endColorstr="#776231",GradientType=1);
  padding: 50px clamp(1%, 8.78477306vw, 120px) 65px 70px;
  border-radius: 30px 0 0 30px;
}
.home .secWrap.reason .reasonList {
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
}
.home .secWrap.reason .reasonList__item {
  text-align: center;
  width: calc((100% - clamp(50px, 7.3206442167vw, 100px)) / 3);
  background: #fff;
  box-shadow: 0 6px 18px rgba(48, 74, 133, 0.1);
  border-radius: 15px;
  padding: 47px 0 40px;
  display: flex;
  flex-direction: column;
}
.home .secWrap.reason .reasonList__item:not(:nth-child(3n+1)) {
  margin-left: clamp(25px, 3.6603221083vw, 50px);
}
.home .secWrap.reason .reasonList__item .icon {
  width: 45%;
  margin: 0 auto 22px;
}
.home .secWrap.reason .reasonList__item .text {
  font-size: clamp(2.5rem, 2.5622254758vw, 3.5rem);
  font-weight: 700;
  line-height: 1.166666;
  margin: auto;
}
.home .secWrap.reason .reasonList__item .text.sm {
  font-size: clamp(2rem, 2.196193265vw, 3rem);
}
.home .secWrap.reason .reasonList__item .numWrap {
  font-size: clamp(2rem, 2.196193265vw, 3rem);
  display: block;
}
.home .secWrap.reason .reasonList__item .numWrap .num {
  font-size: clamp(2rem, 2.196193265vw, 3.4rem);
  font-weight: 700;
  line-height: 1.32352;
  color: #304A85;
  border-bottom: 5px solid #304A85;
  margin: 0 6px;
}
.home .secWrap.reason .reasonList__item .numWrap .num:not(.sm) {
  font-family: "Lato", sans-serif;
  font-size: clamp(4rem, 4.9780380673vw, 6.8rem);
}
.home .secWrap.faq {
  background: url(../img/top/bg_qa_pc.png) no-repeat right top 38px #fff;
  padding: 100px 0 55px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .home .secWrap.faq {
    background-size: 60% auto;
  }
}
.home .secWrap.faq .secWrap__inner {
  padding: 0 70px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .home .secWrap.faq .secWrap__inner {
    padding: 0 5.1244509517vw;
  }
}
.home .secWrap.faq .faqWrap {
  margin-top: 105px;
}
.home .secWrap.faq .faqList {
  padding: 28px 40px;
  box-shadow: 0 5px 25px rgba(34, 34, 34, 0.05);
  border-radius: 15px;
  background: #fff;
}
.home .secWrap.faq .faqList .q {
  font-size: 2.4rem;
  font-weight: 500;
  color: #304A85;
  padding-left: 34px;
  position: relative;
}
.home .secWrap.faq .faqList .q::before {
  font-family: "Lato", sans-serif;
  content: "Q";
  font-size: 2.7rem;
  font-weight: 700;
  color: #304A85;
  display: inline-block;
  position: absolute;
  top: -3px;
  left: 0;
}
.home .secWrap.faq .faqList .q::after {
  content: "";
  width: 50px;
  height: 50px;
  display: inline-block;
  background: url(../img/common/icon/icon_qa_open_pc.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .home .secWrap.faq .faqList .q::after {
    width: 3.6603221083vw;
    height: 3.6603221083vw;
    right: 2.196193265vw;
  }
}
.home .secWrap.faq .faqList .q.open::after {
  background: url(../img/common/icon/icon_qa_close_pc.png) no-repeat center center;
  background-size: contain;
}
.home .secWrap.faq .faqList .a {
  font-size: 1.6rem;
  line-height: 1.75;
  padding-top: 10px;
}
.home .secWrap.faq .faqList .a * + p {
  margin-top: 44px;
}
.home .secWrap.faq .faqList .a a {
  font-weight: 500;
}
.home .secWrap.faq .faqList + .faqList {
  margin-top: 20px;
}
.home .media__ttl {
  color: #fff;
  text-align: center;
  background: url(../img/top/bg_media_pc.jpg) no-repeat center center;
  background-size: cover;
  padding: 65px 1% 79px;
}
.home .media__ttl .ttl {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1.5;
}
.home .media__ttl .ttl::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #fff;
  margin: -5px 15px 0 0;
}
.home .media__ttl .text {
  font-size: 1.6rem;
  line-height: 2;
  margin: 7px 0 0;
}
.home .mediaWrap {
  background: rgb(77, 102, 158);
  background: -moz-linear-gradient(135deg, rgb(77, 102, 158) 0%, rgb(48, 74, 133) 100%);
  background: -webkit-linear-gradient(135deg, rgb(77, 102, 158) 0%, rgb(48, 74, 133) 100%);
  background: linear-gradient(135deg, rgb(77, 102, 158) 0%, rgb(48, 74, 133) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d669e",endColorstr="#304a85",GradientType=1);
  padding: 20px 1% 50px;
}
.home .mediaList {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.home .mediaList__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc((100% - 180px) / 6);
  height: 110px;
  background: #fff;
  border-radius: 5px;
  margin-top: 30px;
  text-align: center;
}
@media screen and (min-width: 767px) {
  .home .mediaList__item:hover a {
    box-shadow: 0 5px 25px rgba(34, 34, 34, 0.5);
  }
  .home .mediaList__item:hover a img {
    transform: scale(1.1);
  }
}
.home .mediaList__item a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home .mediaList__item img {
  display: inline-block;
  vertical-align: middle;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.home .mediaList__item img:not(.vertical) {
  width: 80%;
}
.home .mediaList__item img.vertical {
  width: auto;
  height: 90px;
}
.home .mediaList__item:not(:nth-child(6n+1)) {
  margin-left: 36px;
}
.home .relatedBox {
  background: url(../img/under/bg_related_blue.png) no-repeat right 63px top 0;
  padding: 45px 1% 70px;
}
.home .access {
  background: url(../img/top/bg_access.png) no-repeat right 63px top 52px;
  padding: 100px 0;
}
.home .access__inner {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 0 0 70px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .home .access__inner {
    padding: 0 5.1244509517vw;
  }
}
.home .accessCont {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.home .access__text {
  margin: 0 0 10px;
}
.home .access__gMap {
  width: calc(100% - 366px);
  min-width: 600px;
  padding-top: 44.86%;
  overflow: hidden;
  position: relative;
}
.home .access__gMap iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

/* メインビジュアル */
.mv {
  background: url(../img/top/mv_bg_pc.jpg) no-repeat center top;
  background-size: cover;
  min-height: clamp(380px, 32.3572474378vw, 442px);
  padding: 0 1%;
}
.mv__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.mv__catch {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1.77777;
  color: #fff;
  padding: clamp(80px, 7.6866764275vw, 105px) 0 0;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .mv__catch {
    font-size: 3.2942898975vw;
  }
}
.mv__catch .line {
  background: url(../img/top/mv_textDeco.png) repeat-x left bottom;
  background-size: 3em auto;
  padding-bottom: 10px;
}
.mv + .bnrBox {
  margin-top: clamp(-130px, -9.5168374817vw, -130px);
}

.bnrBox {
  padding: 0 1%;
}
.bnrBox__inner {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.bnrBox__item {
  width: calc((100% - 40px) / 2);
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 5px 25px rgba(34, 34, 34, 0.05);
  padding: 30px 55px 40px;
}
.bnrBox__item:nth-child(2n) {
  margin-left: 40px;
}
.bnrBox__item.yellow .bnrBox__link::before {
  background: #776231;
}
.bnrBox__item.yellow .bnrBox__img::after {
  background: url(../img/top/top_bnr_arrow02_pc.png) no-repeat center center;
  background-size: contain;
}
@media screen and (min-width: 767px) {
  .bnrBox__item:hover {
    box-shadow: 0 5px 25px rgba(34, 34, 34, 0.2);
  }
  .bnrBox__item:hover .bnrBox__link {
    text-decoration: underline;
  }
  .bnrBox__item:hover .bnrBox__img::after {
    right: -43px;
  }
}
.bnrBox__link {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #222;
  text-decoration: none;
  position: relative;
}
.bnrBox__link::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50px;
  background: #304A85;
  position: absolute;
  top: 12px;
  left: -27px;
}
.bnrBox__img {
  position: relative;
}
.bnrBox__img::after {
  content: "";
  width: 76px;
  height: 76px;
  display: inline-block;
  background: url(../img/top/top_bnr_arrow01_pc.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: calc(50% + 15px);
  right: -38px;
  transform: translateY(-50%);
}
.bnrBox__text {
  font-size: 1.6rem;
  line-height: 1.75;
  margin: 17px 0 0;
}

@media screen and (max-width: 767px) {
  /*=================================
    topPage
  =================================*/
  .home .secWrap {
    padding: 60px 4.358974359%;
  }
  .home .secWrap.reason { /* 選ばれる理由 */
    padding: 60px 0 60px 4.358974359%;
    background: url(../img/top/bg_reason.svg) no-repeat right -80px top 76px;
    background-size: 260px auto;
  }
  .home .secWrap.reason .reason__inner {
    padding: 40px 10.2564102564% 40px 6.1538461538%;
    border-radius: 15px 0 0 15px;
  }
  .home .secWrap.reason .reasonList {
    display: block;
  }
  .home .secWrap.reason .reasonList__item {
    width: 100%;
    border-radius: 7px;
    padding: 22px 2.5641025641%;
    display: block;
  }
  .home .secWrap.reason .reasonList__item:not(:nth-child(3n+1)) {
    margin: 20px 0 0;
  }
  .home .secWrap.reason .reasonList__item .icon {
    width: 23%;
    margin: 0 auto 15px;
  }
  .home .secWrap.reason .reasonList__item .text {
    font-size: 2rem;
  }
  .home .secWrap.reason .reasonList__item .text.sm {
    font-size: 2rem;
  }
  .home .secWrap.reason .reasonList__item .numWrap {
    font-size: 2rem;
    display: inline-block;
  }
  .home .secWrap.reason .reasonList__item .numWrap .num {
    font-size: 2.5rem;
    line-height: 1.11428;
    border-bottom: 3px solid #304A85;
  }
  .home .secWrap.reason .reasonList__item .numWrap .num:not(.sm) {
    font-size: 3.5rem;
  }
  .home .secWrap.faq {
    padding: 60px 0;
    background: #fff;
  }
  .home .secWrap.faq .secWrap__inner {
    padding: 0;
  }
  .home .secWrap.faq .secWrap__inner .ttl--base, .home .secWrap.faq .secWrap__inner .ttl--text {
    padding: 0 10.2564102564%;
  }
  .home .secWrap.faq .faqWrap {
    margin-top: 30px;
    background: url(../img/top/bg_qa_sp.png) no-repeat right top;
    background-size: 89.7435897436% auto;
    padding: 40px 4.358974359% 60px 0;
  }
  .home .secWrap.faq .faqList {
    padding: 28px 15.3846153846% 28px 4.6153846154%;
    border-radius: 0 7px 7px 0;
  }
  .home .secWrap.faq .faqList .q {
    font-size: 1.8rem;
    font-weight: 700;
    padding-left: 22px;
  }
  .home .secWrap.faq .faqList .q::before {
    font-size: 1.8rem;
    top: 0;
  }
  .home .secWrap.faq .faqList .q::after {
    width: 36px;
    height: 36px;
    background: url(../img/common/icon/icon_qa_open_sp.png) no-repeat center center;
    background-size: contain;
    right: -15.3846153846%;
  }
  .home .secWrap.faq .faqList .q.open::after {
    background: url(../img/common/icon/icon_qa_close_sp.png) no-repeat center center;
    background-size: contain;
  }
  .home .secWrap.faq .faqList .a {
    font-size: 1.4rem;
    line-height: 1.857142;
    padding: 13px 0 0 22px;
  }
  .home .secWrap.faq .faqList .a * + p {
    margin-top: 22px;
  }
  .home .secWrap.faq .faqList .a a {
    font-weight: 500;
  }
  .home .secWrap.faq .faqList + .faqList {
    margin-top: 20px;
  }
  .home .media__ttl {
    background: url(../img/top/bg_media_sp.jpg) no-repeat center center;
    background-size: cover;
    padding: 40px 10.2564102564%;
  }
  .home .media__ttl .ttl {
    font-size: 2.4rem;
    line-height: 1;
  }
  .home .media__ttl .ttl::before {
    width: 8px;
    height: 8px;
    margin: -5px 9px 0 0;
  }
  .home .media__ttl .text {
    font-size: 1.4rem;
    line-height: 1.85714;
    text-align: left;
  }
  .home .mediaWrap {
    padding: 24px 6.9230769231% 40px;
  }
  .home .mediaList__item {
    width: 47.9487179487%;
    margin-top: 16px;
  }
  .home .mediaList__item img:not(.vertical) {
    width: 80%;
  }
  .home .mediaList__item img.vertical {
    width: auto;
    height: 90px;
  }
  .home .mediaList__item:not(:nth-child(6n+1)) {
    margin-left: 0;
  }
  .home .mediaList__item:nth-child(2n) {
    margin-left: 4.1025641026%;
  }
  .home .relatedBox {
    background: url(../img/under/bg_related_blue.png) no-repeat right -90px top 0;
    padding: 0px 10.2564102564% 30px;
  }
  .home .access {
    background: url(../img/top/bg_access.png) no-repeat right -60px top 0;
    padding: 0 10.2564102564% 60px;
  }
  .home .access__inner {
    padding: 0;
  }
  .home .accessCont {
    display: block;
  }
  .home .access__text {
    margin: 0 0 30px;
  }
  .home .access__gMap {
    width: 100%;
    min-width: initial;
    padding-top: 94.2%;
    border-radius: 15px;
  }
  /* メインビジュアル */
  .mv {
    background: url(../img/top/mv_bg_sp.jpg) no-repeat center top;
    background-size: cover;
    min-height: 650px;
    padding: 0 10.2564102564% 0 4.358974359%;
  }
  .mv__catch {
    font-size: 2.8rem;
    line-height: 1.78571;
    padding: clamp(100px, 29.4871794872vw, 115px) 0 0;
  }
  .mv + .bnrBox {
    margin-top: -264px;
  }
  .bnrBox {
    padding: 0 4.358974359%;
  }
  .bnrBox__inner {
    display: block;
  }
  .bnrBox__item {
    width: 100%;
    border-radius: 10px;
    padding: 30px 5.8974358974%;
    margin-bottom: 20px;
  }
  .bnrBox__item:nth-child(2n) {
    margin-left: 0;
  }
  .bnrBox__link {
    font-size: 2rem;
    line-height: 1.6;
    padding-left: 16px;
  }
  .bnrBox__link::before {
    width: 8px;
    height: 8px;
    left: 0px;
  }
  .bnrBox__img::after {
    width: 36px;
    height: 36px;
    top: calc(50% + 10px);
    right: -18px;
  }
  .bnrBox__text {
    font-size: 1.6rem;
    line-height: 1.75;
    margin: 17px 0 0;
  }
}
/*=================================
  page--article
=================================*/
/* 記事一覧 */
.articleList {
  position: relative;
  z-index: 1;
}
.articleList__item {
  background: #F5F8FF;
  border-radius: 15px;
  max-width: 1000px;
}
@media screen and (min-width: 767px) {
  .articleList__item:hover .textWrap__ttl {
    text-decoration: underline !important;
  }
}
.articleList__item .itemWrap {
  padding: 40px 40px 40px 50px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
.articleList__item .itemWrap .thumb {
  width: 38%;
  max-width: 350px;
  margin-left: 2.8%;
  border-radius: 5px;
  overflow: hidden;
}
.articleList__item .itemWrap .thumb img {
  margin: 0 auto;
}
.articleList__item .itemWrap .thumb + .textWrap {
  width: 59.2%;
}
.articleList__item .itemWrap .textWrap {
  width: 100%;
}
.articleList__item .itemWrap .textWrap__date {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 14px;
}
.articleList__item .itemWrap .textWrap__ttl {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.66666;
  display: block;
  text-decoration: none;
  position: relative;
}
.articleList__item .itemWrap .textWrap__ttl::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  background: #304A85;
  border-radius: 50px;
  position: absolute;
  top: 16px;
  left: -20px;
}
.articleList__item .itemWrap .textWrap__text {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2;
  margin-top: 20px;
}
.articleList__item + .articleList__item {
  margin-top: 40px;
}

.articleListWrap {
  padding: 30px 0 102px calc((100% - 1000px) / 4);
  background: url(../img/under/bg_kv_pc.jpg) no-repeat center top;
  background-size: contain;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleListWrap {
    padding: 30px 0 102px 3%;
  }
}
.articleListWrap__in {
  padding: 40px 70px 40px;
  background: #fff;
  border-radius: 20px 0 0 0;
  position: relative;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleListWrap__in {
    padding: 70px 5.1244509517vw 40px;
  }
}
.articleListWrap__in .ttl--page {
  padding: 0 0 200px;
  margin-bottom: -130px;
  max-width: 1140px;
  background: url(../img/under/bg_articleWrap.png) no-repeat right top;
  background-size: contain;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleListWrap__in .ttl--page {
    padding: 0 0 150px;
    margin-bottom: -120px;
  }
}
.articleListWrap__in .pager + h1 {
  margin-top: 100px;
}
.articleListWrap__in .pager + h2 {
  margin-top: 100px;
}
.articleListWrap__in .pager + h3 {
  margin-top: 100px;
}
.articleListWrap__in .pager + h4 {
  margin-top: 100px;
}
.articleListWrap__in .pager + h5 {
  margin-top: 100px;
}
.articleListWrap__in .pager + h6 {
  margin-top: 100px;
}

/* singlePage */
.singlePage {
  padding: 0 1% 102px;
  margin-top: 30px;
  /*---------------------
    メインビジュアル
  ---------------------*/
  /*---------------------
    目次
  ---------------------*/
}
.singlePage__in {
  padding: 70px 70px 40px;
  background: #fff;
  border-radius: 20px;
  max-width: 1140px;
  margin: 0 auto;
}
.singlePage__in h1, .singlePage__in h2, .singlePage__in h3, .singlePage__in h4, .singlePage__in h5, .singlePage__in h6 {
  clear: both;
}
.singlePage__in p:not([class]) {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2;
}
.singlePage__in p:not([class]) + p {
  margin-top: 20px;
}
.singlePage__in h1:not([class]), .singlePage__in h1.wp-block-heading {
  font-size: 4.2rem;
  font-weight: 700;
  line-height: 1.33333;
  margin-top: 100px;
  margin-bottom: 40px;
  padding-left: 25px;
  position: relative;
}
.singlePage__in h1:not([class])::before, .singlePage__in h1.wp-block-heading::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  position: absolute;
  top: 25px;
  left: 0;
}
.singlePage__in h2:not([class]), .singlePage__in h2.wp-block-heading {
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.66666;
  margin-top: 100px;
  margin-bottom: 40px;
  padding: 28px 30px;
  background: url(../img/common/bg_h2_pc.png) no-repeat right 27px top #304A85;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .singlePage__in h2:not([class]), .singlePage__in h2.wp-block-heading {
    padding: 2.0497803807vw 2.196193265vw;
  }
}
.singlePage__in h3:not([class]), .singlePage__in h3.wp-block-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 60px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 3px solid #E3E3E3;
  position: relative;
}
.singlePage__in h3:not([class])::after, .singlePage__in h3.wp-block-heading::after {
  content: "";
  width: 9em;
  height: 3px;
  display: inline-block;
  background: #304A85;
  position: absolute;
  left: 0;
  bottom: -3px;
}
.singlePage__in h4:not([class]), .singlePage__in h4.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 60px;
  margin-bottom: 20px;
  padding-left: 29px;
  position: relative;
}
.singlePage__in h4:not([class])::before, .singlePage__in h4.wp-block-heading::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  position: absolute;
  top: 10px;
  left: 8px;
}
.singlePage__in h5:not([class]), .singlePage__in h5.wp-block-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.44444;
  color: #304A85;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 10px 15px;
  border-left: 4px solid #304A85;
  background: #F9F9F9;
}
.singlePage__in h6:not([class]), .singlePage__in h6.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.77777;
  color: #304A85;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}
.singlePage__in h6:not([class])::before, .singlePage__in h6.wp-block-heading::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #304A85;
  position: absolute;
  top: 12px;
  left: 0;
}
.singlePage__in blockquote {
  position: relative;
  padding: 50px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 2px solid #F5F8FF;
}
.singlePage__in blockquote::before {
  content: "“";
  font-size: 5rem;
  font-weight: 700;
  color: #304A85;
  display: inline-block;
  font-style: normal;
  width: 50px;
  height: 50px;
  line-height: 1.3;
  text-align: center;
  background: #F5F8FF;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.singlePage__in blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote p {
  font-style: normal;
}
.singlePage__in blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.singlePage__in .btnWrap {
  clear: both;
}
.singlePage__in img:not([class]), .singlePage__in.wp-block-heading {
  display: block;
  margin: 15px auto 20px;
}
.singlePage__in img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.singlePage__in img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.singlePage__in ul:not([class]) {
  margin: 30px 0;
  padding: 30px;
  background: #FFF;
  border: 2px solid #E3E3E3;
  border-radius: 10px;
  clear: both;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .singlePage__in ul:not([class]) {
    padding: 2.196193265vw;
  }
}
.singlePage__in ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2;
  padding-left: 18px;
  position: relative;
}
.singlePage__in ul:not([class]) li::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background: #304A85;
  display: inline-block;
  position: absolute;
  top: 13px;
  left: 0;
}
.singlePage__in ul:not([class]) li + li {
  margin-top: 10px;
}
.singlePage__in ul:not([class]) h1::before {
  content: none;
}
.singlePage__in ul:not([class]) h2::before {
  content: none;
}
.singlePage__in ul:not([class]) h3::before {
  content: none;
}
.singlePage__in ul:not([class]) h4::before {
  content: none;
}
.singlePage__in ul:not([class]) h5::before {
  content: none;
}
.singlePage__in ul:not([class]) h6::before {
  content: none;
}
.singlePage__in ol:not([class]) {
  margin: 30px 0;
  padding: 30px;
  background: #F5F8FF;
  border-radius: 10px;
  counter-reset: listNum;
  clear: both;
}
.singlePage__in ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2;
  padding-left: 38px;
  position: relative;
}
.singlePage__in ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-size: 1.6rem;
  font-weight: 700;
  color: #304A85;
  width: 28px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.singlePage__in ol:not([class]) li + li {
  margin-top: 10px;
}
.singlePage__in ol:not([class]) h1::before {
  content: none;
}
.singlePage__in ol:not([class]) h2::before {
  content: none;
}
.singlePage__in ol:not([class]) h3::before {
  content: none;
}
.singlePage__in ol:not([class]) h4::before {
  content: none;
}
.singlePage__in ol:not([class]) h5::before {
  content: none;
}
.singlePage__in ol:not([class]) h6::before {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .singlePage__in p:not([class]) {
    font-size: 1.4rem;
    line-height: 1.85714;
  }
  .singlePage__in p:not([class]) + p {
    margin-top: 10px;
  }
  .singlePage__in h1:not([class]), .singlePage__in h1.wp-block-heading {
    font-size: 2.4rem;
    margin-top: 54px;
    margin-bottom: 20px;
    padding-left: 15px;
  }
  .singlePage__in h1:not([class])::before, .singlePage__in h1.wp-block-heading::before {
    width: 8px;
    height: 8px;
    top: 13px;
  }
  .singlePage__in h2:not([class]), .singlePage__in h2.wp-block-heading {
    font-size: 1.8rem;
    line-height: 1.38888;
    margin-top: 54px;
    margin-bottom: 20px;
    margin-left: -8.9743589744%;
    padding: 15px 7.6923076923%;
    width: 117.4358974359%;
    background: url(../img/common/bg_h2_sp.png) no-repeat right 17px top #304A85;
    background-size: contain;
  }
  .singlePage__in h3:not([class]), .singlePage__in h3.wp-block-heading {
    font-size: 1.6rem;
    line-height: 1.625;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-bottom: 14px;
  }
  .singlePage__in h3:not([class])::after, .singlePage__in h3.wp-block-heading::after {
    width: 6.5em;
  }
  .singlePage__in h4:not([class]), .singlePage__in h4.wp-block-heading {
    font-size: 1.6rem;
    line-height: 1.625;
    margin-top: 40px;
    padding-left: 20px;
  }
  .singlePage__in h4:not([class])::before, .singlePage__in h4.wp-block-heading::before {
    top: 10px;
    left: 5px;
  }
  .singlePage__in h5:not([class]), .singlePage__in h5.wp-block-heading {
    font-size: 1.5rem;
    line-height: 1.73333;
    padding: 10px 12px;
  }
  .singlePage__in h6:not([class]), .singlePage__in h6.wp-block-heading {
    font-size: 1.5rem;
    line-height: 1.73333;
    padding-left: 18px;
  }
  .singlePage__in h6:not([class])::before, .singlePage__in h6.wp-block-heading::before {
    width: 8px;
    height: 8px;
    top: 9px;
  }
  .singlePage__in blockquote {
    padding: 40px 3.8461538462% 20px;
    margin: 40px 0 30px;
    box-sizing: border-box;
    font-style: italic;
    color: #464646;
    border: 2px solid #F5F8FF;
  }
  .singlePage__in blockquote::before {
    font-size: 4rem;
    width: 45px;
    height: 45px;
    line-height: 1.5;
  }
  .singlePage__in blockquote h1 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h2 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h3 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h4 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h5 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h6 {
    font-size: 1.5rem;
  }
  .singlePage__in img.alignleft {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .singlePage__in img.alignright {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .singlePage__in ul:not([class]) {
    margin: 20px 0;
    padding: 25px 6.4102564103%;
    border-radius: 5px;
  }
  .singlePage__in ul:not([class]) li {
    font-size: 1.4rem;
    line-height: 1.71428;
    padding-left: 14px;
  }
  .singlePage__in ul:not([class]) li::before {
    width: 6px;
    height: 6px;
    top: 8px;
  }
  .singlePage__in ol:not([class]) {
    margin: 20px 0;
    padding: 25px 3.8461538462%;
    border-radius: 5px;
  }
  .singlePage__in ol:not([class]) li {
    font-size: 1.4rem;
    line-height: 1.71428;
  }
  .singlePage__in ol:not([class]) li::before {
    font-size: 1.4rem;
  }
}
.singlePage__in iframe {
  width: 100%;
}
.singlePage__in > h1:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h2:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h3:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h4:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h5:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h6:first-child {
  margin-top: 0 !important;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .singlePage__in {
    padding: 70px 5.1244509517vw 40px;
  }
}
.singlePage .articleMv {
  margin: 40px auto;
  text-align: center;
  overflow: hidden;
}
.singlePage .articleMv img {
  border-radius: 15px;
}
.singlePage .tocBox {
  margin: 40px auto 100px;
}
.singlePage .tocBox__inner {
  background: #fff;
  border: 1px solid #DFDFDF;
  padding: 34px 4.0995607613vw;
}
.singlePage .tocBox__ttl {
  font-size: 2.9rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 24px;
  margin-left: 32px;
}
.singlePage .tocBox__ttl::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  background: #304A85;
  border-radius: 50px;
  vertical-align: middle;
  margin: -5px 11px 0 0;
}
.singlePage .tocBox .tocList {
  counter-reset: tocNum;
}
.singlePage .tocBox .tocList__item {
  position: relative;
  border-bottom: 1px dashed #D4D4D4;
}
.singlePage .tocBox .tocList__item::before {
  counter-increment: tocNum;
  content: counter(tocNum) ".";
  font-size: 1.6rem;
  font-weight: 400;
  color: #304A85;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 32px;
}
.singlePage .tocBox .tocList__item a {
  font-size: 1.6rem;
  font-weight: 400;
  display: inline-block;
  padding: 0 0 10px 60px;
}
@media screen and (min-width: 767px) {
  .singlePage .tocBox .tocList__item a:hover {
    text-decoration: underline;
    color: #304A85;
  }
}
.singlePage .tocBox .tocList__item + .tocList__item {
  margin-top: 5px;
}
.singlePage .tocBox .tocList__item .tocList {
  counter-reset: tocChildNum;
  padding-left: 26px;
}
.singlePage .tocBox .tocList__item .tocList__item::before {
  counter-increment: tocChildNum;
  content: counter(tocNum) " - " counter(tocChildNum) ".";
}
.singlePage .tocBox .tocList__item .tocList__item a {
  padding-left: 36px;
}
.singlePage .tocBox .tocList__item .tocList__item + .tocList__item {
  margin-top: 5px;
}
.singlePage .tocBox .tocMore {
  font-size: 1.5rem;
  font-weight: 500;
  background: #FECB3F;
  text-align: center;
  margin-top: 20px;
  padding: 8px 20px;
  display: inline-block;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
@media screen and (min-width: 767px) {
  .singlePage .tocBox .tocMore:hover {
    color: #fff;
    background: #304A85;
  }
}
.singlePage .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item:nth-child(n+4) {
  display: none;
}
.singlePage .tocBox:not(.open) .tocMore::after {
  transform: rotate(0deg);
}
@media screen and (min-width: 767px) {
  .singlePage .tocBox:not(.open) .tocMore:hover::after {
    transform: translateY(2px) rotate(90deg);
  }
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .singlePage {
    /*---------------------
      記事タイトル
    ---------------------*/
    /*---------------------
      メインビジュアル
    ---------------------*/
    /*---------------------
      目次
    ---------------------*/
  }
  .singlePage .articleTtl {
    font-size: 2.2rem;
    line-height: 1.5;
    padding: 0 3.8461538462%;
  }
  .singlePage .articleMv {
    margin: 20px auto;
    padding: 0 3.8461538462%;
  }
  .singlePage .tocBox {
    margin: 30px auto;
    padding: 0 3.8461538462%;
  }
  .singlePage .tocBox__inner {
    padding: 20px 3.8461538462%;
  }
  .singlePage .tocBox__ttl {
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 20px;
  }
  .singlePage .tocBox .tocList__item::before {
    top: 0;
  }
  .singlePage .tocBox .tocMore {
    font-size: 1.4rem;
  }
}

/* 記事詳細ページ */
.articleDetail {
  /*---------------------
    メインビジュアル
  ---------------------*/
  /*---------------------
    目次
  ---------------------*/
  padding: 30px 0 102px calc((100% - 1000px) / 4);
  background: url(../img/under/bg_kv_pc.jpg) no-repeat center top;
  background-size: contain;
}
.articleDetail .articleMv {
  margin: 40px auto;
  text-align: center;
  overflow: hidden;
}
.articleDetail .articleMv img {
  border-radius: 15px;
}
.articleDetail .tocBox {
  margin: 40px auto 100px;
}
.articleDetail .tocBox__inner {
  background: #fff;
  border: 1px solid #DFDFDF;
  padding: 34px 4.0995607613vw;
}
.articleDetail .tocBox__ttl {
  font-size: 2.9rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 24px;
  margin-left: 32px;
}
.articleDetail .tocBox__ttl::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  background: #304A85;
  border-radius: 50px;
  vertical-align: middle;
  margin: -5px 11px 0 0;
}
.articleDetail .tocBox .tocList {
  counter-reset: tocNum;
}
.articleDetail .tocBox .tocList__item {
  position: relative;
  border-bottom: 1px dashed #D4D4D4;
}
.articleDetail .tocBox .tocList__item::before {
  counter-increment: tocNum;
  content: counter(tocNum) ".";
  font-size: 1.6rem;
  font-weight: 400;
  color: #304A85;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 32px;
}
.articleDetail .tocBox .tocList__item a {
  font-size: 1.6rem;
  font-weight: 400;
  display: inline-block;
  padding: 0 0 10px 60px;
}
@media screen and (min-width: 767px) {
  .articleDetail .tocBox .tocList__item a:hover {
    text-decoration: underline;
    color: #304A85;
  }
}
.articleDetail .tocBox .tocList__item + .tocList__item {
  margin-top: 5px;
}
.articleDetail .tocBox .tocList__item .tocList {
  counter-reset: tocChildNum;
  padding-left: 26px;
}
.articleDetail .tocBox .tocList__item .tocList__item::before {
  counter-increment: tocChildNum;
  content: counter(tocNum) " - " counter(tocChildNum) ".";
}
.articleDetail .tocBox .tocList__item .tocList__item a {
  padding-left: 36px;
}
.articleDetail .tocBox .tocList__item .tocList__item + .tocList__item {
  margin-top: 5px;
}
.articleDetail .tocBox .tocMore {
  font-size: 1.5rem;
  font-weight: 500;
  background: #FECB3F;
  text-align: center;
  margin-top: 20px;
  padding: 8px 20px;
  display: inline-block;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
@media screen and (min-width: 767px) {
  .articleDetail .tocBox .tocMore:hover {
    color: #fff;
    background: #304A85;
  }
}
.articleDetail .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item:nth-child(n+4) {
  display: none;
}
.articleDetail .tocBox:not(.open) .tocMore::after {
  transform: rotate(0deg);
}
@media screen and (min-width: 767px) {
  .articleDetail .tocBox:not(.open) .tocMore:hover::after {
    transform: translateY(2px) rotate(90deg);
  }
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .articleDetail {
    /*---------------------
      記事タイトル
    ---------------------*/
    /*---------------------
      メインビジュアル
    ---------------------*/
    /*---------------------
      目次
    ---------------------*/
  }
  .articleDetail .articleTtl {
    font-size: 2.2rem;
    line-height: 1.5;
    padding: 0 3.8461538462%;
  }
  .articleDetail .articleMv {
    margin: 20px auto;
    padding: 0 3.8461538462%;
  }
  .articleDetail .tocBox {
    margin: 30px auto;
    padding: 0 3.8461538462%;
  }
  .articleDetail .tocBox__inner {
    padding: 20px 3.8461538462%;
  }
  .articleDetail .tocBox__ttl {
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 20px;
  }
  .articleDetail .tocBox .tocList__item::before {
    top: 0;
  }
  .articleDetail .tocBox .tocMore {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleDetail {
    padding: 30px 0 102px 3%;
  }
}
.articleDetail__in {
  padding: 40px 70px 40px;
  background: #fff;
  border-radius: 20px 0 0 0;
  position: relative;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleDetail__in {
    padding: 70px 5.1244509517vw 40px;
  }
}
.articleDetail__in .tocBox + * {
  margin-top: 0 !important;
}
.articleDetail .articleCont {
  max-width: 1000px;
}
.articleDetail .articleCont h1, .articleDetail .articleCont h2, .articleDetail .articleCont h3, .articleDetail .articleCont h4, .articleDetail .articleCont h5, .articleDetail .articleCont h6 {
  clear: both;
}
.articleDetail .articleCont p:not([class]) {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2;
}
.articleDetail .articleCont p:not([class]) + p {
  margin-top: 20px;
}
.articleDetail .articleCont h1:not([class]), .articleDetail .articleCont h1.wp-block-heading {
  font-size: 4.2rem;
  font-weight: 700;
  line-height: 1.33333;
  margin-top: 100px;
  margin-bottom: 40px;
  padding-left: 25px;
  position: relative;
}
.articleDetail .articleCont h1:not([class])::before, .articleDetail .articleCont h1.wp-block-heading::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  position: absolute;
  top: 25px;
  left: 0;
}
.articleDetail .articleCont h2:not([class]), .articleDetail .articleCont h2.wp-block-heading {
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.66666;
  margin-top: 100px;
  margin-bottom: 40px;
  padding: 28px 30px;
  background: url(../img/common/bg_h2_pc.png) no-repeat right 27px top #304A85;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleDetail .articleCont h2:not([class]), .articleDetail .articleCont h2.wp-block-heading {
    padding: 2.0497803807vw 2.196193265vw;
  }
}
.articleDetail .articleCont h3:not([class]), .articleDetail .articleCont h3.wp-block-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 60px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 3px solid #E3E3E3;
  position: relative;
}
.articleDetail .articleCont h3:not([class])::after, .articleDetail .articleCont h3.wp-block-heading::after {
  content: "";
  width: 9em;
  height: 3px;
  display: inline-block;
  background: #304A85;
  position: absolute;
  left: 0;
  bottom: -3px;
}
.articleDetail .articleCont h4:not([class]), .articleDetail .articleCont h4.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 60px;
  margin-bottom: 20px;
  padding-left: 29px;
  position: relative;
}
.articleDetail .articleCont h4:not([class])::before, .articleDetail .articleCont h4.wp-block-heading::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  position: absolute;
  top: 10px;
  left: 8px;
}
.articleDetail .articleCont h5:not([class]), .articleDetail .articleCont h5.wp-block-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.44444;
  color: #304A85;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 10px 15px;
  border-left: 4px solid #304A85;
  background: #F9F9F9;
}
.articleDetail .articleCont h6:not([class]), .articleDetail .articleCont h6.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.77777;
  color: #304A85;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}
.articleDetail .articleCont h6:not([class])::before, .articleDetail .articleCont h6.wp-block-heading::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #304A85;
  position: absolute;
  top: 12px;
  left: 0;
}
.articleDetail .articleCont blockquote {
  position: relative;
  padding: 50px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 2px solid #F5F8FF;
}
.articleDetail .articleCont blockquote::before {
  content: "“";
  font-size: 5rem;
  font-weight: 700;
  color: #304A85;
  display: inline-block;
  font-style: normal;
  width: 50px;
  height: 50px;
  line-height: 1.3;
  text-align: center;
  background: #F5F8FF;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.articleDetail .articleCont blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail .articleCont blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail .articleCont blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail .articleCont blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail .articleCont blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail .articleCont blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail .articleCont blockquote p {
  font-style: normal;
}
.articleDetail .articleCont blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.articleDetail .articleCont .btnWrap {
  clear: both;
}
.articleDetail .articleCont img:not([class]), .articleDetail .articleCont.wp-block-heading {
  display: block;
  margin: 15px auto 20px;
}
.articleDetail .articleCont img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.articleDetail .articleCont img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.articleDetail .articleCont ul:not([class]) {
  margin: 30px 0;
  padding: 30px;
  background: #FFF;
  border: 2px solid #E3E3E3;
  border-radius: 10px;
  clear: both;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleDetail .articleCont ul:not([class]) {
    padding: 2.196193265vw;
  }
}
.articleDetail .articleCont ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2;
  padding-left: 18px;
  position: relative;
}
.articleDetail .articleCont ul:not([class]) li::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background: #304A85;
  display: inline-block;
  position: absolute;
  top: 13px;
  left: 0;
}
.articleDetail .articleCont ul:not([class]) li + li {
  margin-top: 10px;
}
.articleDetail .articleCont ul:not([class]) h1::before {
  content: none;
}
.articleDetail .articleCont ul:not([class]) h2::before {
  content: none;
}
.articleDetail .articleCont ul:not([class]) h3::before {
  content: none;
}
.articleDetail .articleCont ul:not([class]) h4::before {
  content: none;
}
.articleDetail .articleCont ul:not([class]) h5::before {
  content: none;
}
.articleDetail .articleCont ul:not([class]) h6::before {
  content: none;
}
.articleDetail .articleCont ol:not([class]) {
  margin: 30px 0;
  padding: 30px;
  background: #F5F8FF;
  border-radius: 10px;
  counter-reset: listNum;
  clear: both;
}
.articleDetail .articleCont ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2;
  padding-left: 38px;
  position: relative;
}
.articleDetail .articleCont ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-size: 1.6rem;
  font-weight: 700;
  color: #304A85;
  width: 28px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.articleDetail .articleCont ol:not([class]) li + li {
  margin-top: 10px;
}
.articleDetail .articleCont ol:not([class]) h1::before {
  content: none;
}
.articleDetail .articleCont ol:not([class]) h2::before {
  content: none;
}
.articleDetail .articleCont ol:not([class]) h3::before {
  content: none;
}
.articleDetail .articleCont ol:not([class]) h4::before {
  content: none;
}
.articleDetail .articleCont ol:not([class]) h5::before {
  content: none;
}
.articleDetail .articleCont ol:not([class]) h6::before {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .articleDetail .articleCont p:not([class]) {
    font-size: 1.4rem;
    line-height: 1.85714;
  }
  .articleDetail .articleCont p:not([class]) + p {
    margin-top: 10px;
  }
  .articleDetail .articleCont h1:not([class]), .articleDetail .articleCont h1.wp-block-heading {
    font-size: 2.4rem;
    margin-top: 54px;
    margin-bottom: 20px;
    padding-left: 15px;
  }
  .articleDetail .articleCont h1:not([class])::before, .articleDetail .articleCont h1.wp-block-heading::before {
    width: 8px;
    height: 8px;
    top: 13px;
  }
  .articleDetail .articleCont h2:not([class]), .articleDetail .articleCont h2.wp-block-heading {
    font-size: 1.8rem;
    line-height: 1.38888;
    margin-top: 54px;
    margin-bottom: 20px;
    margin-left: -8.9743589744%;
    padding: 15px 7.6923076923%;
    width: 117.4358974359%;
    background: url(../img/common/bg_h2_sp.png) no-repeat right 17px top #304A85;
    background-size: contain;
  }
  .articleDetail .articleCont h3:not([class]), .articleDetail .articleCont h3.wp-block-heading {
    font-size: 1.6rem;
    line-height: 1.625;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-bottom: 14px;
  }
  .articleDetail .articleCont h3:not([class])::after, .articleDetail .articleCont h3.wp-block-heading::after {
    width: 6.5em;
  }
  .articleDetail .articleCont h4:not([class]), .articleDetail .articleCont h4.wp-block-heading {
    font-size: 1.6rem;
    line-height: 1.625;
    margin-top: 40px;
    padding-left: 20px;
  }
  .articleDetail .articleCont h4:not([class])::before, .articleDetail .articleCont h4.wp-block-heading::before {
    top: 10px;
    left: 5px;
  }
  .articleDetail .articleCont h5:not([class]), .articleDetail .articleCont h5.wp-block-heading {
    font-size: 1.5rem;
    line-height: 1.73333;
    padding: 10px 12px;
  }
  .articleDetail .articleCont h6:not([class]), .articleDetail .articleCont h6.wp-block-heading {
    font-size: 1.5rem;
    line-height: 1.73333;
    padding-left: 18px;
  }
  .articleDetail .articleCont h6:not([class])::before, .articleDetail .articleCont h6.wp-block-heading::before {
    width: 8px;
    height: 8px;
    top: 9px;
  }
  .articleDetail .articleCont blockquote {
    padding: 40px 3.8461538462% 20px;
    margin: 40px 0 30px;
    box-sizing: border-box;
    font-style: italic;
    color: #464646;
    border: 2px solid #F5F8FF;
  }
  .articleDetail .articleCont blockquote::before {
    font-size: 4rem;
    width: 45px;
    height: 45px;
    line-height: 1.5;
  }
  .articleDetail .articleCont blockquote h1 {
    font-size: 1.5rem;
  }
  .articleDetail .articleCont blockquote h2 {
    font-size: 1.5rem;
  }
  .articleDetail .articleCont blockquote h3 {
    font-size: 1.5rem;
  }
  .articleDetail .articleCont blockquote h4 {
    font-size: 1.5rem;
  }
  .articleDetail .articleCont blockquote h5 {
    font-size: 1.5rem;
  }
  .articleDetail .articleCont blockquote h6 {
    font-size: 1.5rem;
  }
  .articleDetail .articleCont img.alignleft {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .articleDetail .articleCont img.alignright {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .articleDetail .articleCont ul:not([class]) {
    margin: 20px 0;
    padding: 25px 6.4102564103%;
    border-radius: 5px;
  }
  .articleDetail .articleCont ul:not([class]) li {
    font-size: 1.4rem;
    line-height: 1.71428;
    padding-left: 14px;
  }
  .articleDetail .articleCont ul:not([class]) li::before {
    width: 6px;
    height: 6px;
    top: 8px;
  }
  .articleDetail .articleCont ol:not([class]) {
    margin: 20px 0;
    padding: 25px 3.8461538462%;
    border-radius: 5px;
  }
  .articleDetail .articleCont ol:not([class]) li {
    font-size: 1.4rem;
    line-height: 1.71428;
  }
  .articleDetail .articleCont ol:not([class]) li::before {
    font-size: 1.4rem;
  }
}
.articleDetail .articleCont iframe {
  width: 100%;
}

/* 関連記事 */
.relatedBox {
  padding: 100px 1% 50px;
  background: url(../img/under/bg_related.png) no-repeat right 40px top 50px;
}
.relatedBox.blue {
  background: url(../img/under/bg_articleWrap.png) no-repeat right 40px top 50px #F5F8FF;
}
.relatedBox__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.relatedBox__ttl {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1.5;
}
.relatedBox__ttl::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  margin: -5px 15px 0 0;
}
.relatedBox__text {
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 7px;
}
.relatedBox .relatedList {
  margin-top: 30px;
}

.relatedList {
  display: flex;
  flex-wrap: wrap;
}
.relatedList__item {
  width: calc((100% - 100px) / 3);
  margin-bottom: 50px;
}
.relatedList__item:not(:nth-child(3n+1)) {
  margin-left: 50px;
}
@media screen and (min-width: 767px) {
  .relatedList__item:hover .textWrap__ttl {
    text-decoration: underline !important;
  }
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .relatedList__item {
    width: calc((100% - 7.3206442167vw) / 3);
    margin-bottom: 3.6603221083vw;
  }
  .relatedList__item:not(:nth-child(3n+1)) {
    margin-left: 3.6603221083vw;
  }
}
.relatedList__item .itemWrap {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
.relatedList__item .itemWrap .thumb {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.relatedList__item .itemWrap .thumb img {
  margin: 0 auto;
}
.relatedList__item .itemWrap .thumb .label {
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  display: inline-block;
  background: rgba(34, 34, 34, 0.6);
  padding: 7px 16px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.relatedList__item .itemWrap .textWrap {
  width: 100%;
}
.relatedList__item .itemWrap .textWrap__date {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 14px;
}
.relatedList__item .itemWrap .textWrap__ttl {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.625;
  display: block;
  text-decoration: none;
}
.relatedList__item .itemWrap .textWrap__text {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2;
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  /* singlePage */
  .singlePage {
    padding: 0 5.1282051282%;
    margin-top: 20px;
  }
  .singlePage__in {
    padding: 20px 3.8461538462% 42px;
    overflow: hidden;
  }
  .singlePage__in > h1:first-child {
    margin-top: -20px !important;
  }
  .singlePage__in > h2:first-child {
    margin-top: -20px !important;
  }
  .singlePage__in > h3:first-child {
    margin-top: -20px !important;
  }
  .singlePage__in > h4:first-child {
    margin-top: -20px !important;
  }
  .singlePage__in > h5:first-child {
    margin-top: -20px !important;
  }
  .singlePage__in > h6:first-child {
    margin-top: -20px !important;
  }
  /* 記事詳細ページ */
  .articleDetail {
    padding: 20px 0 47px 7.6923076923%;
    background: url(../img/under/bg_kv_sp.jpg) no-repeat center top;
    background-size: contain;
  }
  .articleDetail__in {
    padding: 20px 7.6923076923% 20px 0;
  }
  /* 関連記事 */
  .relatedBox {
    padding: 40px 10.2564102564%;
    background: url(../img/under/bg_related.png) no-repeat right -65px top 72px;
    background-size: 210px auto;
  }
  .relatedBox.blue {
    background: url(../img/under/bg_articleWrap.png) no-repeat right -80px top 40px #F5F8FF;
    background-size: 260px auto;
  }
  .relatedBox__ttl {
    font-size: 2.4rem;
    line-height: 1.083333;
  }
  .relatedBox__ttl::before {
    width: 6px;
    height: 6px;
    margin: -5px 8px 0 0;
  }
  .relatedBox__text {
    font-size: 1.4rem;
    margin-top: 10px;
  }
  .relatedList {
    display: block;
  }
  .relatedList__item {
    width: 100%;
    margin-bottom: 30px;
  }
  .relatedList__item:not(:nth-child(3n+1)) {
    margin-left: 0;
  }
  .relatedList__item .itemWrap {
    display: block;
  }
  .relatedList__item .itemWrap .thumb {
    margin-bottom: 12px;
    border-radius: 5px;
  }
  .relatedList__item .itemWrap .thumb .label {
    font-size: 1rem;
  }
  .relatedList__item .itemWrap .textWrap__date {
    margin-bottom: 12px;
  }
  .relatedList__item .itemWrap .textWrap__ttl {
    font-size: 1.4rem;
    line-height: 1.71428;
  }
  .relatedList__item .itemWrap .textWrap__text {
    line-height: 1.66666;
    display: none;
  }
  /* 記事一覧ページ */
  .articleList__item {
    border-radius: 10px;
  }
  .articleList__item .itemWrap {
    padding: 20px 4.358974359% 20px 6.9230769231%;
    display: block;
  }
  .articleList__item .itemWrap .thumb {
    width: 100%;
    max-width: initial;
    margin: 0 0 15px;
    text-align: center;
  }
  .articleList__item .itemWrap .thumb + .textWrap {
    width: 100%;
  }
  .articleList__item .itemWrap .textWrap__ttl {
    font-size: 1.6rem;
  }
  .articleList__item .itemWrap .textWrap__ttl::before {
    width: 6px;
    height: 6px;
    top: 12px;
    left: -4.358974359%;
  }
  .articleList__item .itemWrap .textWrap__text {
    font-size: 1.4rem;
    line-height: 1.66666;
    margin-top: 10px;
    display: none;
  }
  .articleList__item + .articleList__item {
    margin-top: 20px;
  }
  .articleListWrap {
    padding: 25px 0 25px 6.4102564103%;
    background: url(../img/under/bg_kv_sp.jpg) no-repeat center top;
    background-size: contain;
  }
  .articleListWrap__in {
    padding: 20px 7.6923076923% 20px 0;
  }
  .articleListWrap__in .ttl--page {
    padding: 0 0 60px 6.9230769231%;
    margin-bottom: -30px;
    background-size: 50% auto;
    background-position: right bottom;
  }
  .articleListWrap__in .ttl--page .ttl {
    padding-left: 0;
  }
  .articleListWrap__in .ttl--page .ttl::before {
    left: -4.358974359%;
  }
  .articleListWrap__in .pager + h1 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h2 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h3 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h4 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h5 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h6 {
    margin-top: 50px;
  }
}
/*=================================
  お問い合わせ
=================================*/
.formBox h1 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h2 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h3 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h4 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h5 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h6 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox .infoBox {
  display: flex;
  align-items: flex-start;
  margin-top: 50px;
}
.formBox .infoBox .thumb {
  width: 42%;
  max-width: 359px;
  margin-right: 46px;
}
.formBox .infoBox .thumb img {
  margin: 0;
}
.formBox .infoBox .contact {
  width: calc(100% - (42% + 46px));
}
.formBox .infoBox .contact .infoBoxTtl {
  font-size: 2rem;
  line-height: 1.8;
  font-weight: 400;
}
.formBox .infoBox .contact .address .info--tel {
  margin-top: 20px;
}
.formBox .infoBox .contact .address .info--tel .tel .num {
  font-size: 4.9rem;
  font-weight: bold;
  line-height: 1;
  text-decoration: none;
}
@media screen and (min-width: 767px) {
  .formBox .infoBox .contact .address .info--tel .tel .num {
    pointer-events: none;
  }
}
.formBox .infoBox .contact .address .info--tel .tel .num::before {
  content: "";
  width: 38px;
  height: 38px;
  display: inline-block;
  background: url(../img/common/icon/icon_tel_w.png) no-repeat center center;
  background-size: contain;
  margin: 0 10px -2px 0;
}
.formBox .infoBox .contact .address .info--tel .tel .num:hover {
  opacity: 0.8;
}
.formBox .infoBox .contact .address .info--tel .receptionTime {
  display: flex;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 20px;
}
.formBox .infoBox .contact .address .info--tel .receptionTime dt {
  width: 5em;
  font-weight: bold;
}
.formBox .infoBox .contact .address .info--tel .receptionTime dd {
  width: calc(100% - 5em);
}
.formBox .step {
  display: inline-block;
  counter-reset: stepNum;
  margin-bottom: 55px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.formBox .step::before {
  content: "";
  width: calc(100% - 5.5rem);
  height: 1px;
  display: inline-block;
  background: #D2D2D2;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.formBox .step__item {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  padding-top: 26px;
  display: inline-block;
  position: relative;
}
.formBox .step__item::before {
  counter-increment: stepNum;
  content: "STEP0" counter(stepNum);
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.625;
  color: #304A85;
  margin-bottom: 10px;
}
.formBox .step__item::after {
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50px;
  background: #D2D2D2;
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
}
.formBox .step__item.active::after {
  background: #304A85;
}
.formBox .step__item + .step__item {
  margin-left: 59px;
}
.formBox .btnWrap {
  margin-top: 40px;
}
.formBox .btn--form {
  display: block;
  text-align: center;
}
.formBox .btn--form:not(.back) input {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 30px 68px 28px;
  border-radius: 5px;
  background: #304A85;
  box-shadow: 0px 5px 0 0px #162545;
  cursor: pointer;
}
@media screen and (min-width: 767px) {
  .formBox .btn--form:not(.back):hover input {
    box-shadow: none !important;
    transform: translateY(5px);
  }
}
.formBox .btn--form.back input {
  font-size: 1.4rem;
  color: #999;
  cursor: pointer;
}

/* =================================
  お問い合わせフォーム
================================= */
.text--security {
  font-size: 1.6rem;
  margin-bottom: 30px;
  text-align: center;
}

.contactForm table {
  width: 100%;
}
.contactForm th {
  font-size: 1.6rem;
  padding: 15px 65px 15px 20px;
  text-align: left;
  width: 240px;
  position: relative;
}
.contactForm th .sub {
  font-size: 1.4rem;
  display: block;
}
.contactForm th span:not(.sub) {
  font-size: 1.2rem;
  font-weight: normal;
  letter-spacing: 0.04em;
  line-height: 1;
  color: #fff;
  background: #D2D2D2;
  padding: 5px 12px;
  position: absolute;
  top: 20px;
  right: 10px;
}
.contactForm th .required {
  background: #FC2828 !important;
}
.contactForm td {
  font-size: 1.6rem;
  padding: 17px 15px 17px 20px;
  width: calc(100% - 240px);
  text-align: left;
}
.contactForm td p {
  line-height: 1.65;
}
.contactForm td * {
  font-size: 1.6rem;
}
.contactForm td input, .contactForm td textarea, .contactForm td select {
  background: #fff;
  border: 2px solid #D2D2D2;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}
.contactForm td textarea {
  min-height: 170px;
}
.contactForm td .error {
  font-size: 1.4rem;
  color: #FC2828;
  margin: 10px 0 0;
  display: inline-block;
}
.contactForm .confirmingTxt {
  text-align: center;
  margin: 40px 0;
}
.contactForm .confirmingTxt p {
  font-size: 1.6rem;
}
.contactForm .confirmingTxt .checkText {
  font-size: 1.8rem;
  margin-top: 40px;
  display: inline-block;
}
.contactForm .confirmingTxt .checkText input {
  margin-right: 10px;
}
.contactForm .confirmingTxt input {
  -webkit-appearance: checkbox;
}
.contactForm .confirmingTxt a {
  font-weight: bold;
  text-decoration: none;
}
.contactForm .confirmingTxt a:hover {
  text-decoration: underline;
}

.consultForm__item {
  display: flex;
  align-items: center;
  background: #F5F8FF;
  padding: 38px 20px;
  border: 1px solid #D2D2D2;
}
.consultForm__item + .consultForm__item {
  margin-top: 20px;
}
.consultForm__ttl {
  font-size: 1.6rem;
  font-weight: bold;
  color: #304A85;
  width: 230px;
  padding-right: 55px;
  position: relative;
}
.consultForm__ttl .sub {
  font-size: 1.4rem;
  display: block;
}
.consultForm__ttl span:not(.sub) {
  font-size: 1.2rem;
  font-weight: normal;
  letter-spacing: 0.04em;
  line-height: 1;
  color: #fff;
  background: #D2D2D2;
  padding: 5px 12px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.consultForm__ttl .required {
  background: #FC2828 !important;
}
.consultForm__list {
  font-size: 1.6rem;
  width: calc(93% - 230px);
  padding-left: 27px;
}
.consultForm__list p {
  line-height: 1.65;
}
.consultForm__list * {
  font-size: 1.6rem;
}
.consultForm__list input:not([type=radio]):not([type=checkbox]), .consultForm__list textarea, .consultForm__list select {
  background: #fff;
  border: 2px solid #D2D2D2;
  padding: 10px;
}
.consultForm__list input:not([type=radio]):not([type=checkbox]):not([class=widthNormal]), .consultForm__list textarea, .consultForm__list select {
  width: 100%;
  box-sizing: border-box;
}
.consultForm__list .mwform-radio-field {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 3rem;
}
.consultForm__list .mwform-radio-field label {
  display: inline-block;
}
.consultForm__list input[type=radio] + span {
  padding-left: 2.8rem;
  display: inline-block;
  position: relative;
}
.consultForm__list input[type=radio] + span::before {
  content: "";
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  margin: auto;
  border-radius: 50%;
  border: solid 2px #D2D2D2;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.consultForm__list input[type=radio]:checked + span::after {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: auto 0;
  background-color: #304A85;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4px;
}
.consultForm__list .mwform-checkbox-field {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 3rem;
}
.consultForm__list .mwform-checkbox-field label {
  display: inline-block;
}
.consultForm__list input[type=checkbox] + span {
  padding-left: 2.8rem;
  display: inline-block;
  position: relative;
}
.consultForm__list input[type=checkbox] + span::before {
  content: "";
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  margin: auto;
  border-radius: 4px;
  border: solid 2px #D2D2D2;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.consultForm__list input[type=checkbox]:checked + span::after {
  content: "";
  width: 11px;
  height: 7px;
  display: inline-block;
  background: url(../img/common/icon/icon_check.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 10px;
  left: 5px;
}
.consultForm__list textarea {
  min-height: 170px;
}
.consultForm__list .error {
  font-size: 1.4rem;
  color: #FC2828;
  margin: 10px 0 0;
  display: block;
}
.consultForm__list .yoyaku {
  display: flex;
  align-items: center;
}
.consultForm__list .yoyaku .rank {
  font-weight: bold;
  color: #304A85;
  width: 85px;
}
.consultForm__list .yoyaku .yoyakuBox {
  width: calc(100% - 85px);
  display: flex;
}
.consultForm__list .yoyaku .yoyakudate {
  width: calc((100% - 15px) / 2) !important;
}
.consultForm__list .yoyaku .yoyakujikan {
  width: calc((100% - 15px) / 2) !important;
  margin-left: 15px;
}
.consultForm__list .yoyaku + .yoyaku {
  margin-top: 15px;
}
.consultForm .confirmingTxt {
  text-align: center;
  margin: 37px 0;
}
.consultForm .confirmingTxt p {
  font-size: 1.4rem;
}
.consultForm .confirmingTxt input {
  -webkit-appearance: checkbox;
}
.consultForm .confirmingTxt a {
  text-decoration: underline;
}
.consultForm .confirmingTxt a:hover {
  color: #304A85;
}
.consultForm + .consultForm {
  border-top: 1px dashed #D2D2D2;
  padding-top: 20px;
  margin-top: 20px;
}

.completedForm .completedMessage {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.06em;
  display: inline-block;
  margin: 24px 0 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.completedForm .completedMessage::after {
  content: "";
  width: 100%;
  height: 3px;
  background: #304A85;
  display: inline-block;
}
.completedForm p {
  font-size: 1.5rem;
  text-align: center;
  margin: 40px 0 0;
}
.completedForm table {
  width: initial;
  margin: 40px auto 45px;
}
.completedForm th, .completedForm td {
  width: auto;
}
.completedForm th {
  font-size: 1.6rem;
  font-weight: bold;
  vertical-align: middle;
  padding: 15px 30px;
}
.completedForm td {
  vertical-align: middle;
  padding: 15px 30px;
}
.completedForm td .tel {
  font-size: 3.4rem;
  font-weight: bold;
  color: #304A85;
  line-height: 1;
  text-decoration: none;
  margin: 0;
}
@media screen and (min-width: 767px) {
  .completedForm td .tel {
    pointer-events: none;
  }
}
.completedForm td .receptionTime {
  display: flex;
  margin: 10px 0 0;
}
.completedForm td .receptionTime dt {
  font-size: 1.3rem;
  font-weight: bold;
  color: #304A85;
  width: 5.5em;
}
.completedForm td .receptionTime dd {
  font-size: 1.3rem;
  width: calc(100% - 5.5em);
}

@media screen and (max-width: 767px) {
  /*=================================
    お問い合わせ
  =================================*/
  .formBox h1 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h2 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h3 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h4 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h5 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h6 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox .infoBox {
    display: block;
    margin-top: 0;
    overflow: hidden;
  }
  .formBox .infoBox .thumb {
    width: 30%;
    margin-right: 0;
    margin-bottom: 15px;
    float: left;
  }
  .formBox .infoBox .contact {
    width: 100%;
  }
  .formBox .infoBox .contact .infoBoxTtl {
    font-size: 1.5rem;
    line-height: 1.5;
    width: calc(100% - (30% + 15px));
    margin-left: auto;
  }
  .formBox .infoBox .contact .address .info--tel {
    margin-top: 0;
  }
  .formBox .infoBox .contact .address .info--tel .tel {
    width: calc(100% - (30% + 15px));
    margin-left: auto;
    margin-top: 15px;
  }
  .formBox .infoBox .contact .address .info--tel .tel .num {
    font-size: 1.8rem;
    color: #fff;
    background: #304A85;
    display: block;
    padding: 10px 10px;
    border-radius: 3px;
    text-align: center;
  }
  .formBox .infoBox .contact .address .info--tel .tel .num::before {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin: 0 7px -3px 0;
  }
  .formBox .infoBox .contact .address .info--tel .receptionTime {
    width: 100%;
    font-size: 1.4rem;
    margin-top: 15px;
  }
  .formBox .step {
    margin-bottom: 40px;
  }
  .formBox .step::before {
    width: calc(100% - 5rem);
  }
  .formBox .step__item {
    font-size: 1.6rem;
    padding-top: 20px;
  }
  .formBox .step__item::before {
    font-size: 1.4rem;
    margin-bottom: 5px;
  }
  .formBox .step__item::after {
    width: 13px;
    height: 13px;
    top: -6px;
  }
  .formBox .step__item.active::after {
    background: #304A85;
  }
  .formBox .step__item + .step__item {
    margin-left: 15.1282051282vw;
  }
  .formBox .btnWrap {
    margin-top: 35px;
  }
  .formBox .btn--form:not(.back) input {
    font-size: 1.6rem;
    padding: 15px 3.5897435897% 13px;
    border-radius: 4px;
    box-shadow: 0px 4px 0 0px #162545;
  }
  /* =================================
    お問い合わせフォーム
  ================================= */
  .text--security {
    font-size: 1.4rem;
  }
  .contactForm table {
    display: table;
    white-space: initial;
  }
  .contactForm th {
    font-size: 1.5rem;
    padding: 15px 3.5897435897%;
    width: 100%;
    display: block;
  }
  .contactForm th span:not(.sub) {
    font-size: 1.1rem;
    letter-spacing: 0.4em;
    padding: 4px 4px 4px 8px;
    margin-left: 1.5rem;
    position: static;
    transform: translateY(0);
  }
  .contactForm td {
    padding: 15px 3.5897435897%;
    width: 100%;
    display: block;
  }
  .contactForm td p {
    line-height: 1.5;
  }
  .contactForm td input, .contactForm td textarea, .contactForm td select {
    padding: 12px;
  }
  .contactForm td .error {
    font-size: 1.3rem;
  }
  .contactForm .confirmingTxt {
    margin: 30px 0;
  }
  .contactForm .confirmingTxt p {
    font-size: 1.4rem;
  }
  .contactForm .confirmingTxt .checkText {
    font-size: 1.6rem;
    margin-top: 10px;
  }
  .consultForm__item {
    display: block;
    padding: 10px 3.5897435897% 20px;
  }
  .consultForm__ttl {
    font-size: 1.5rem;
    padding: 15px 0;
    width: 100%;
    display: block;
  }
  .consultForm__ttl .sub {
    font-size: 1.4rem;
    display: inline-block;
  }
  .consultForm__ttl span:not(.sub) {
    font-size: 1.1rem;
    letter-spacing: 0.4em;
    padding: 4px 4px 4px 8px;
    margin-left: 1.5rem;
    position: static;
    transform: translateY(0);
  }
  .consultForm__list {
    width: 100%;
    padding-left: 0;
  }
  .consultForm__list p {
    line-height: 1.5;
  }
  .consultForm__list .mwform-radio-field {
    margin-right: 0.5rem;
  }
  .consultForm__list .mwform-checkbox-field {
    margin-right: 0.5rem;
  }
  .consultForm__list input[type=checkbox]:checked + span::after {
    width: 15px;
    height: 10px;
    top: 6px;
    left: 3px;
  }
  .consultForm__list .error {
    font-size: 1.3rem;
  }
  .consultForm__list .yoyaku {
    flex-wrap: wrap;
  }
  .consultForm__list .yoyaku .rank {
    width: 100%;
    margin-bottom: 10px;
  }
  .consultForm__list .yoyaku .yoyakuBox {
    width: 100%;
    display: block;
  }
  .consultForm__list .yoyaku .yoyakudate {
    width: 100% !important;
  }
  .consultForm__list .yoyaku .yoyakujikan {
    width: 100% !important;
    margin: 10px 0 0;
  }
  .consultForm__list .yoyaku + .yoyaku {
    margin-top: 20px;
  }
  .consultForm .confirmingTxt {
    text-align: center;
    margin: 37px 0;
  }
  .consultForm .confirmingTxt p {
    font-size: 1.4rem;
  }
  .completedForm p {
    margin: 30px 0 0;
  }
  .completedForm table {
    display: table;
    white-space: initial;
    margin: 40px auto 45px;
  }
  .completedForm th, .completedForm td {
    width: auto;
  }
  .completedForm th {
    font-size: 1.4rem;
    padding: 15px 3.5897435897%;
  }
  .completedForm td {
    padding: 15px 3.5897435897%;
  }
  .completedForm td .tel {
    font-size: 3rem;
  }
  .completedForm td .receptionTime {
    text-align: left;
  }
}
/* =================================
  header
================================= */
.header {
  width: 100%;
  background: #fff;
  padding: 16px 1%;
  box-shadow: 0 5px 25px rgba(34, 34, 34, 0.05);
}
.header__inner {
  max-width: 1270px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.header__logo {
  width: clamp(180px, 14.6412884334vw, 200px);
}
.header__logo .logo {
  display: inline-block;
  vertical-align: middle;
}
.header__logo .logo a {
  display: block;
}
@media screen and (min-width: 767px) {
  .header__logo .logo a:hover {
    opacity: 0.8;
  }
}
.header__logo .text {
  font-size: 1.4rem;
  display: inline-block;
  vertical-align: middle;
}
.header .contact {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .contact {
    margin-bottom: 20px;
  }
}
.header .contact__wrap {
  text-align: center;
}
.header .contact__wrap .contactText {
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.05em;
  display: block;
  margin: 5px 0 0;
}
.header .contact__wrap .contactText.sm {
  letter-spacing: 0;
}
.header .contact__wrap.tel {
  padding: 10px 30px;
  position: relative;
}
@media screen and (min-width: 767px) {
  .header .contact__wrap.tel {
    min-width: 218px;
  }
}
.header .contact__wrap.tel .telNum {
  font-family: "Lato", sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.208333;
  color: #222;
  text-decoration: none;
  display: block;
  position: relative;
}
@media screen and (min-width: 767px) {
  .header .contact__wrap.tel .telNum {
    pointer-events: none;
  }
}
.header .contact__wrap.tel .telNum::before {
  content: "";
  width: 22px;
  height: 22px;
  display: inline-block;
  mask-image: url(../img/common/icon/icon_tel.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: #222;
  background-size: contain;
  position: absolute;
  top: 5px;
  left: -30px;
}
.header .contact__wrap.reservation {
  background: #F0F0F0;
  border-radius: 5px;
  box-shadow: 0 3px 0 #CCCCCC;
  padding: 7px 20px 10px 48px;
  margin: 0 0 0 10px;
  position: relative;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) {
  .header .contact__wrap.reservation {
    min-width: 258px;
  }
}
.header .contact__wrap.reservation::before {
  content: "";
  width: 20px;
  height: 14px;
  display: inline-block;
  mask-image: url(../img/common/icon/icon_mail.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: #222;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 19px;
  transform: translateY(-50%);
}
@media screen and (min-width: 767px) {
  .header .contact__wrap.reservation:hover {
    box-shadow: none;
    transform: translateY(3px);
  }
}
.header .contact__wrap.reservation .reservationLink {
  font-size: 1.9rem;
  font-weight: 500;
  line-height: 1.421052;
  color: #222;
  text-decoration: none;
  display: block;
}
.header .gNav {
  margin-left: auto;
}
.header .gNav__inner {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNav__inner {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}
.header .gNavList__inner {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.header .gNavList__item {
  display: inline-block;
  position: relative;
  margin: 0 25px 10px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item {
    margin: 0 1.8301610542vw 10px;
  }
}
.header .gNavList__item::after {
  content: "";
  width: 0;
  height: 2px;
  display: block;
  background: #304A85;
  position: absolute;
  left: 50%;
  bottom: -7px;
  transform: translateX(-50%);
  z-index: 0;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) {
  .header .gNavList__item:hover::after {
    width: 100%;
  }
}
.header .gNavList__item a {
  font-size: 1.4rem;
  font-weight: 500;
  color: #222;
  line-height: 1.428571;
  text-decoration: none;
  display: block;
  position: relative;
  z-index: 2;
}
.header .gNavList__item.current-menu-item a {
  color: #304A85;
}
.header .gNavList__item.current-menu-item a::after {
  width: 100%;
}

@media screen and (min-width: 920px) {
  .gNavList.fixedNav {
    padding: 30px 1%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 5px 25px rgba(34, 34, 34, 0.05);
    z-index: 999;
    animation: header-show 0.5s ease-in;
  }
  .gNavList.fixedNav .gNavList__inner {
    justify-content: center;
  }
}
@media screen and (max-width: 920px) {
  /* =================================
    header
  ================================= */
  .header {
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    /*バーガーボタン設定*/
  }
  .header__inner {
    display: block;
  }
  .header__logo {
    width: 100%;
    min-height: 60px;
    padding: 13px 0 13px 3.3333333333%;
  }
  .header__logo .logo {
    width: 31.7948717949vw;
    line-height: 1;
  }
  .header__logo .text {
    display: none;
  }
  .header .contact {
    display: block;
    margin: 30px auto 0;
  }
  .header .contact__wrap .contactText {
    font-size: 1.4rem;
  }
  .header .contact__wrap .contactText.sm {
    font-size: 1.3rem;
  }
  .header .contact__wrap.tel {
    padding: 16px 7.6923076923%;
    border: 2px solid #EEEEEE;
    border-radius: 5px;
    background: #fff;
    position: relative;
  }
  .header .contact__wrap.tel::before {
    content: "";
    width: 30px;
    height: 30px;
    display: inline-block;
    mask-image: url(../img/common/icon/icon_tel.svg);
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: #222;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 7.6923076923%;
    transform: translateY(-50%);
  }
  .header .contact__wrap.tel .telNum {
    font-size: 3.1rem;
    line-height: 1.225806;
  }
  .header .contact__wrap.tel .telNum::before {
    content: none;
  }
  .header .contact__wrap.reservation {
    box-shadow: 0 4px 0 #CCCCCC;
    padding: 16px 7.6923076923%;
    margin: 20px 0 0;
  }
  .header .contact__wrap.reservation::before {
    width: 31px;
    height: 22px;
    left: 8.9743589744%;
  }
  .header .contact__wrap.reservation .reservationLink {
    font-size: 2.2rem;
    line-height: 1.454545;
  }
  .header .gNav {
    overflow-y: scroll;
    max-height: 90vh;
    width: 100%;
    margin: auto;
    padding: 40px 6.4102564103%;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 9999;
    transform: scale(0.7);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, transform 0.3s ease-out, visibility 0.2s ease-out;
    background: url(../img/common/bg_burger.svg) no-repeat right bottom #fff;
  }
  .header .gNav__inner {
    display: block;
  }
  .header .gNavList__inner {
    display: block;
  }
  .header .gNavList__item {
    display: block;
    margin: 0;
  }
  .header .gNavList__item::after {
    content: none;
  }
  .header .gNavList__item a {
    padding: 20px 40px 20px 3.8461538462%;
    border-radius: 5px;
    background: rgb(235, 232, 225);
    background: -moz-linear-gradient(95deg, rgb(235, 232, 225) 0%, rgb(224, 228, 236) 100%);
    background: -webkit-linear-gradient(95deg, rgb(235, 232, 225) 0%, rgb(224, 228, 236) 100%);
    background: linear-gradient(95deg, rgb(235, 232, 225) 0%, rgb(224, 228, 236) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ebe8e1",endColorstr="#e0e4ec",GradientType=1);
  }
  .header .gNavList__item a::after {
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    background: url(../img/common/icon/icon_gnav_arrow.svg) no-repeat center center;
    background-size: contain;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
  .header .gNavList__item + .gNavList__item {
    margin-top: 20px;
  }
  .header .gNav.show {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
  }
  .header .gNav.hide {
    transform: scale(0.7);
    opacity: 0;
    visibility: hidden;
  }
  .header .headerNav__cover {
    height: 0;
    width: 100vw;
    background: #fff;
    background-size: cover;
    position: fixed;
    top: 60px;
    left: 0;
    display: none;
    z-index: 9998;
  }
  .header .headerNav__cover.show {
    display: block;
    animation: show 0.2s linear 0s;
  }
  .header .headerNav__cover.hide {
    display: none;
    animation: hide 0.2s linear 0s;
  }
  .header .burger {
    width: 60px;
    height: 60px;
    background: #fff;
    margin: auto;
    border-left: 2px solid #F6F5F3;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 9999;
    /*クリック後、バツボタンになる*/
  }
  .header .burger::after {
    content: "";
    width: 18px;
    height: 16px;
    display: inline-block;
    background: url(../img/common/btn_burger_open.svg) no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .header .burger.is-open::after {
    width: 16px;
    height: 16px;
    background: url(../img/common/btn_burger_close.svg) no-repeat center center;
  }
}
/*=================================
footer
=================================*/
.footer {
  background: #fff;
}
.footer__inner {
  max-width: calc(1260px + 2%);
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px 1%;
}
.footer__logo {
  width: 200px;
}
.footer__sns {
  margin-left: 66px;
}
.footer__sns .snsList__item {
  width: 20px;
  display: inline-block;
  margin-left: 10px;
}
.footer__sns .snsList__item a {
  display: block;
  line-height: 1;
}
@media screen and (min-width: 767px) {
  .footer__sns .snsList__item a:hover img {
    filter: invert(26%) sepia(11%) saturate(3171%) hue-rotate(184deg) brightness(102%) contrast(93%);
  }
}
.footer__cont {
  margin-left: auto;
}
.footer .fNavList__item {
  display: inline-block;
  margin-left: 30px;
}
.footer .fNavList__item a {
  font-size: 1.4rem;
  font-weight: 400;
  color: #222;
  text-decoration: none;
}
@media screen and (min-width: 767px) {
  .footer .fNavList__item a:hover {
    text-decoration: underline;
    color: #304A85;
  }
}
.footer .copyWrap {
  background: #304A85;
  padding: 16px 1%;
}
.footer .copyWrap__inner {
  max-width: 1260px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.footer .copyWrap__inner > * {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #fff;
}
.footer .copy {
  font-family: "Lato", sans-serif;
}

.links {
  padding: 46px 1% 48px;
  background: rgb(232, 236, 245);
  background: -moz-linear-gradient(95deg, rgb(232, 236, 245) 0%, rgb(230, 226, 215) 100%);
  background: -webkit-linear-gradient(95deg, rgb(232, 236, 245) 0%, rgb(230, 226, 215) 100%);
  background: linear-gradient(95deg, rgb(232, 236, 245) 0%, rgb(230, 226, 215) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e8ecf5",endColorstr="#e6e2d7",GradientType=1);
}
.links__text {
  font-size: 1.6rem;
  text-align: center;
}
.linksBox {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.linksBox .linksBox__item {
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 3px 15px rgba(34, 34, 34, 0.05);
  border-radius: 10px;
  width: calc((100% - 40px) / 2);
  padding: 32px 24px;
  margin-top: 38px;
}
@media screen and (min-width: 767px) {
  .linksBox .linksBox__item:hover {
    box-shadow: 0 3px 15px rgba(34, 34, 34, 0.3);
  }
  .linksBox .linksBox__item:hover .textWrap .link {
    text-decoration: underline;
  }
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .linksBox .linksBox__item {
    width: calc((100% - 2.9282576867vw) / 2);
  }
}
.linksBox .linksBox__item:nth-child(2n) {
  margin-left: 40px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .linksBox .linksBox__item:nth-child(2n) {
    margin-left: 2.9282576867vw;
  }
}
.linksBox .linksBox__item.blue {
  position: relative;
  padding-right: 74px;
}
.linksBox .linksBox__item.blue::after {
  content: "";
  width: 43px;
  height: 43px;
  display: inline-block;
  background: url(../img/common/icon/icon_arrow_blue_pc.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
}
@media screen and (min-width: 767px) {
  .linksBox .linksBox__item.blue:hover::after {
    right: 20px;
  }
}
.linksBox .linksBox__item.yellow {
  position: relative;
  padding-right: 74px;
}
.linksBox .linksBox__item.yellow::after {
  content: "";
  width: 43px;
  height: 43px;
  display: inline-block;
  background: url(../img/common/icon/icon_arrow_yellow_pc.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
}
.linksBox .linksBox__item.yellow .textWrap .link::before {
  background: #776231;
}
@media screen and (min-width: 767px) {
  .linksBox .linksBox__item.yellow:hover::after {
    right: 20px;
  }
}
.linksBox .linksBox__item .img {
  width: 100px;
  height: auto;
  margin-right: 14px;
}
.linksBox .linksBox__item .img + .textWrap {
  width: calc(100% - 114px);
}
.linksBox .linksBox__item .textWrap .link {
  font-size: 1.8rem;
  font-weight: 700;
  color: #222;
  text-decoration: none;
  padding-left: 18px;
  position: relative;
}
.linksBox .linksBox__item .textWrap .link:before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #304A85;
  border-radius: 50px;
  position: absolute;
  top: 10px;
  left: 0;
}
.linksBox .linksBox__item .textWrap .text {
  font-size: 1.4rem;
  line-height: 1.71428;
  padding-left: 17px;
  margin-top: 12px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
    footer
  =================================*/
  .footer__inner {
    display: block;
    padding: 30px 2.8205128205%;
    text-align: center;
  }
  .footer__logo {
    width: 170px;
    margin: 0 auto;
  }
  .footer__sns {
    margin: 32px 0 0;
  }
  .footer__sns .snsList__item {
    width: 26px;
    margin: 0 10px;
  }
  .footer__cont {
    margin: 30px auto 0;
  }
  .footer .fNavList__item {
    margin: 0 10px;
  }
  .footer .copyWrap {
    padding: 19px 2.8205128205%;
    text-align: center;
  }
  .footer .copyWrap__inner {
    display: block;
  }
  .links {
    padding: 40px 4.358974359%;
  }
  .links__text {
    font-size: 1.4rem;
    line-height: 1.857142;
    text-align: left;
    margin-bottom: 30px;
  }
  .linksBox {
    display: block;
  }
  .linksBox .linksBox__item {
    align-items: flex-start;
    border-radius: 5px;
    width: 100%;
    padding: 20px 3.8461538462%;
    margin-top: 20px;
  }
  .linksBox .linksBox__item:nth-child(2n) {
    margin-left: 0;
  }
  .linksBox .linksBox__item.blue {
    padding-right: 65px;
  }
  .linksBox .linksBox__item.blue::after {
    width: 32px;
    height: 32px;
    background: url(../img/common/icon/icon_arrow_blue_sp.png) no-repeat center center;
    background-size: contain;
    right: 5.1282051282%;
  }
  .linksBox .linksBox__item.yellow {
    padding-right: 65px;
  }
  .linksBox .linksBox__item.yellow::after {
    width: 32px;
    height: 32px;
    background: url(../img/common/icon/icon_arrow_yellow_sp.png) no-repeat center center;
    background-size: contain;
    right: 5.1282051282%;
  }
  .linksBox .linksBox__item .img {
    width: 74px;
    margin-right: 10px;
  }
  .linksBox .linksBox__item .img + .textWrap {
    width: calc(100% - 84px);
  }
  .linksBox .linksBox__item .textWrap .link {
    font-size: 1.6rem;
  }
  .linksBox .linksBox__item .textWrap .link:before {
    width: 8px;
    height: 8px;
    top: 8px;
  }
  .linksBox .linksBox__item .textWrap .text {
    margin-top: 9px;
  }
}
/*=================================
  flow
=================================*/
.flow {
  padding: 90px 1% 40px;
  background: #F5F8FF;
}
.flow__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.flowList {
  counter-reset: flowNum;
}
.flowList__item {
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.06);
  position: relative;
}
.flowList__item:nth-child(1)::before {
  content: "";
  width: calc(100% - (100% - 270px));
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../img/flow_img01.jpg) no-repeat center top;
  background-size: cover;
}
.flowList__item:nth-child(2)::before {
  content: "";
  width: calc(100% - (100% - 270px));
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../img/flow_img02.jpg) no-repeat center top;
  background-size: cover;
}
.flowList__item:nth-child(3)::before {
  content: "";
  width: calc(100% - (100% - 270px));
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../img/flow_img03.jpg) no-repeat center top;
  background-size: cover;
}
.flowList__item .flowCont {
  background: #fff;
  padding: 60px;
  width: calc(100% - 270px);
  margin-left: auto;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .flowList__item .flowCont {
    padding: 4.39238653vw;
  }
}
.flowList__item .flowCont__ttl {
  font-size: 2.4rem;
  font-weight: 500;
  color: #304A85;
  padding-left: 43px;
  margin-bottom: 30px;
  position: relative;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .flowList__item .flowCont__ttl {
    font-size: 1.9033674963vw;
    padding-left: 3.4407027818vw;
  }
}
.flowList__item .flowCont__ttl::before {
  counter-increment: flowNum;
  content: counter(flowNum);
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  width: 35px;
  line-height: 35px;
  border-radius: 50px;
  text-align: center;
  background: #304A85;
  position: absolute;
  top: 7px;
  left: 0;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .flowList__item .flowCont__ttl::before {
    font-size: 1.4641288433vw;
    width: 2.9282576867vw;
    line-height: 2.9282576867vw;
    top: 0.5124450952vw;
  }
}
.flowList__item .flowCont__text {
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 2;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .flowList__item .flowCont__text {
    font-size: 1.8301610542vw;
  }
}
.flowList__item .flowCont__text em, .flowList__item .flowCont__text .em {
  font-size: 1.7rem;
  font-weight: 600;
  background: linear-gradient(transparent 92%, #FC2828 0%);
  padding-bottom: 2px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .flowList__item .flowCont__text em, .flowList__item .flowCont__text .em {
    font-size: 1.8301610542vw;
  }
}
.flowList__item:not(:last-child)::after {
  content: "";
  border-style: solid;
  border-width: 21px 17.5px 0 17.5px;
  border-color: #304A85 transparent transparent transparent;
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: -41px;
  transform: translateX(-50%);
}
.flowList__item + .flowList__item {
  margin-top: 60px;
}

@media screen and (max-width: 767px) {
  /*=================================
    flow
  =================================*/
  .flow {
    padding: 40px 0 16px;
  }
  .flowList__item:nth-child(1)::before {
    width: calc(100% - (100% - 100px));
    background: url(../img/flow_img01_sp.jpg) no-repeat center top;
    background-size: cover;
  }
  .flowList__item:nth-child(2)::before {
    width: calc(100% - (100% - 100px));
    background: url(../img/flow_img02_sp.jpg) no-repeat center top;
    background-size: cover;
  }
  .flowList__item:nth-child(3)::before {
    width: calc(100% - (100% - 100px));
    background: url(../img/flow_img03_sp.jpg) no-repeat center top;
    background-size: cover;
  }
  .flowList__item .flowCont {
    padding: 40px 7.6923076923%;
    width: calc(100% - 100px);
  }
  .flowList__item .flowCont__ttl {
    font-size: 1.8rem;
    line-height: 1.555;
    padding-left: 36px;
    margin-bottom: 20px;
    position: relative;
  }
  .flowList__item .flowCont__ttl::before {
    font-size: 1.3rem;
    width: 30px;
    line-height: 30px;
    top: 0px;
  }
  .flowList__item .flowCont__text {
    font-size: 1.5rem;
    line-height: 1.8666;
  }
  .flowList__item .flowCont__text em, .flowList__item .flowCont__text .em {
    font-size: 1.5rem;
    color: #FC2828;
  }
}