@use "../global" as *;
/*=================================
  flow
=================================*/
.flow {
  padding: 90px 1% 40px;
  background: $color-theme-light;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  &List {
    counter-reset: flowNum;
    &__item {
      box-shadow: 0px 3px 16px rgba(0, 0, 0, .06);
      position: relative;
      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          &::before {
            content: "";
            width: calc(100% - (100% - 270px));
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background: url(../img/flow_img0#{$i}.jpg) no-repeat center top;
            background-size: cover;
          }
        }
      }
      .flowCont {
        background: #fff;
        padding: 60px;
        width: calc(100% - 270px);
        margin-left: auto;
        @include mq(tab) {
          padding: vw(60);
        }
        &__ttl {
          font-size: 2.4rem;
          font-weight: 500;
          color: $color-theme;
          padding-left: 43px;
          margin-bottom: 30px;
          position: relative;
          @include mq(tab) {
            font-size: vw(26);
            padding-left: vw(47);
          }
          &::before {
            counter-increment: flowNum;
            content: counter(flowNum);
            font-size: 1.6rem;
            font-weight: 700;
            color: #fff;
            display: inline-block;
            width: 35px;
            line-height: 35px;
            border-radius: 50px;
            text-align: center;
            background: $color-theme;
            position: absolute;
            top: 7px;
            left: 0;
            @include mq(tab) {
              font-size: vw(20);
              width: vw(40);
              line-height: vw(40);
              top: vw(7);
            }
          }
        }
        &__text {
          font-size: 1.7rem;
          font-weight: 400;
          line-height: 2;
          @include mq(tab) {
            font-size: vw(25);
          }
          em,.em {
            font-size: 1.7rem;
            font-weight: 600;
            background: linear-gradient(transparent 92%, $color-red 0%);
            padding-bottom: 2px;
            @include mq(tab) {
              font-size: vw(25);
            }
          }
        }
      }
      &:not(:last-child) {
        &::after {
          content: "";
          border-style: solid;
          border-width: 21px 17.5px 0 17.5px;
          border-color: $color-theme transparent transparent transparent;
          display: inline-block;
          position: absolute;
          left: 50%;
          bottom: -41px;
          transform: translateX(-50%);
        }
      }
      & + .flowList__item {
        margin-top: 60px;
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    flow
  =================================*/
  .flow {
    padding: 40px 0 16px;
    &List {
      &__item {
        @for $i from 1 through 3 {
          &:nth-child(#{$i}) {
            &::before {
              width: calc(100% - (100% - 100px));
              background: url(../img/flow_img0#{$i}_sp.jpg) no-repeat center top;
              background-size: cover;
            }
          }
        }
        .flowCont {
          padding: 40px per(30);
          width: calc(100% - 100px);
          &__ttl {
            font-size: 1.8rem;
            line-height: 1.555;
            padding-left: 36px;
            margin-bottom: 20px;
            position: relative;
            &::before {
              font-size: 1.3rem;
              width: 30px;
              line-height: 30px;
              top: 0px;
            }
          }
          &__text {
            font-size: 1.5rem;
            line-height: 1.8666;
            em,.em {
              font-size: 1.5rem;
              color: $color-red;
            }
          }
        }
      }
    }
  }
}