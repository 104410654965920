@use "../../global" as *;
/*=================================
  topPage
=================================*/
.home {
  .secWrap {
    padding: 90px 1% 60px;
    &__inner {
      max-width: $base-width;
      margin: 0 auto;
    }
    &.reason { /* 選ばれる理由 */
      padding: 100px 0 64px calc((100% - $base-width) / 2);
      background: url(../img/top/bg_reason.svg) no-repeat right 63px top 142px;
      background-size: 300px auto;
      @include mq(tab) {
        padding: 100px 0 64px 1%;
      }
      .reason__inner {
        background: rgb(48,74,133);
        background: -moz-linear-gradient(95deg, rgba(48,74,133,0.15) 0%, rgba(119,98,49,0.15) 100%);
        background: -webkit-linear-gradient(95deg, rgba(48,74,133,0.15) 0%, rgba(119,98,49,0.15) 100%);
        background: linear-gradient(95deg, rgba(48,74,133,0.15) 0%, rgba(119,98,49,0.15) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#304a85",endColorstr="#776231",GradientType=1);
        padding: 50px clamp(1%, vw(120), 120px) 65px 70px;
        border-radius: 30px 0 0 30px;
      }
      .reasonList {
        max-width: 1000px;
        display: flex;
        flex-wrap: wrap;
        &__item {
          text-align: center;
          width: calc((100% - clamp(50px, vw(100), 100px)) / 3);
          background: #fff;
          box-shadow: 0 6px 18px rgba(48, 74, 133, .1);
          border-radius: 15px;
          padding: 47px 0 40px;
          display: flex;
          flex-direction: column;
          &:not(:nth-child(3n + 1)) {
            margin-left: clamp(25px, vw(50), 50px);
          }
          .icon {
            width: 45%;
            margin: 0 auto 22px;
          }
          .text {
            font-size: clamp(2.5rem, vw(35), 3.5rem);
            font-weight: 700;
            line-height: 1.166666;
            margin: auto;
            &.sm {
              font-size: clamp(2rem, vw(30), 3rem);
            }
          }
          .numWrap {
            font-size: clamp(2rem, vw(30), 3rem);
            display: block;
            .num {
              font-size: clamp(2rem, vw(30), 3.4rem);
              font-weight: 700;
              line-height: 1.32352;
              color: $color-theme;
              border-bottom: 5px solid $color-theme;
              margin: 0 6px;
              &:not(.sm) {
                @include font-lato;
                font-size: clamp(4rem, vw(68), 6.8rem);
              }
            }
          }
        }
      }
    }
    &.faq {
      background: url(../img/top/bg_qa_pc.png) no-repeat right top 38px #fff;
      padding: 100px 0 55px;
      @include mq(tab) {
        background-size: 60% auto;
      }
      .secWrap__inner {
        padding: 0 70px;
        @include mq(tab) {
          padding: 0 vw(70);
        }
      }
      .faqWrap {
        margin-top: 105px;
      }
      .faqList {
        padding: 28px 40px;
        box-shadow: 0 5px 25px rgba(34, 34, 34, .05);
        border-radius: 15px;
        background: #fff;
        .q {
          font-size: 2.4rem;
          font-weight: 500;
          color: $color-theme;
          padding-left: 34px;
          position: relative;
          &::before {
            @include font-lato;
            content: "Q";
            font-size: 2.7rem;
            font-weight: 700;
            color: $color-theme;
            display: inline-block;
            position: absolute;
            top: -3px;
            left: 0;
          }
          &::after {
            content: "";
            width: 50px;
            height: 50px;
            display: inline-block;
            background: url(../img/common/icon/icon_qa_open_pc.png) no-repeat center center;
            background-size: contain;
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
            @include animation-base;
            @include mq(tab) {
              width: vw(50);
              height: vw(50);
              right: vw(30);
            }
          }
          &.open {
            &::after {
              background: url(../img/common/icon/icon_qa_close_pc.png) no-repeat center center;
              background-size: contain;
            }
          }
        }
        .a {
          font-size: 1.6rem;
          line-height: 1.75;
          padding-top: 10px;
          * + p {
            margin-top: 44px;
          }
          a {
            font-weight: 500;
          }
        }
        & + .faqList {
          margin-top: 20px;
        }
      }
    }
  }
  .media {
    &__ttl {
      color: #fff;
      text-align: center;
      background: url(../img/top/bg_media_pc.jpg) no-repeat center center;
      background-size: cover;
      padding: 65px 1% 79px;
      .ttl {
        font-size: 4.5rem;
        font-weight: 700;
        line-height: 1.5;
        &::before {
          content: "";
          width: 12px;
          height: 12px;
          display: inline-block;
          vertical-align: middle;
          border-radius: 50px;
          background: #fff;
          margin: -5px 15px 0 0;
        }
      }
      .text {
        font-size: 1.6rem;
        line-height: 2;
        margin: 7px 0 0;
      }
    }
    &Wrap {
      background: rgb(77,102,158);
      background: -moz-linear-gradient(135deg, rgba(77,102,158,1) 0%, rgba(48,74,133,1) 100%);
      background: -webkit-linear-gradient(135deg, rgba(77,102,158,1) 0%, rgba(48,74,133,1) 100%);
      background: linear-gradient(135deg, rgba(77,102,158,1) 0%, rgba(48,74,133,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d669e",endColorstr="#304a85",GradientType=1);
      padding: 20px 1% 50px;
    }
    &List {
      max-width: $base-width;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc((100% - 180px) / 6);
        height: 110px;
        background: #fff;
        border-radius: 5px;
        margin-top: 30px;
        text-align: center;
        @include mq(pc) {
          &:hover {
            a {
              box-shadow: 0 5px 25px rgba(34, 34, 34, .5);
              img {
                transform: scale(1.1);
              }
            }
          }
        }
        a {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          display: inline-block;
          vertical-align: middle;
          @include animation-base;
          &:not(.vertical) {
            width: 80%;
          }
          &.vertical {
            width: auto;
            height: 90px;
          }
        }
        &:not(:nth-child(6n + 1)) {
          margin-left: 36px;
        }
      }
    }
  }
  .relatedBox {
    background: url(../img/under/bg_related_blue.png) no-repeat right 63px top 0;
    padding: 45px 1% 70px;
  }
  .access {
    background: url(../img/top/bg_access.png) no-repeat right 63px top 52px;
    padding: 100px 0;
    &__inner {
      max-width: $base-width;
      margin: 0 auto;
      padding: 0 0 0 70px;
      @include mq(tab) {
        padding: 0 vw(70);
      }
    }
    &Cont {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    &__text {
      margin: 0 0 10px;
    }
    &__gMap {
      width: calc(100% - 366px);
      min-width: 600px;
      padding-top: 44.86%;
      overflow: hidden;
      position: relative;
      iframe {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
}
/* メインビジュアル */
.mv {
  background: url(../img/top/mv_bg_pc.jpg) no-repeat center top;
  background-size: cover;
  min-height: clamp(380px, vw(442), 442px);
  padding: 0 1%;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  &__catch {
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 1.77777;
    color: #fff;
    padding: clamp(80px, vw(105), 105px) 0 0;
    @include mq(tab) {
      font-size: vw(45);
    }
    .line {
      background: url(../img/top/mv_textDeco.png) repeat-x left bottom;
      background-size: 3em auto;
      padding-bottom: 10px;
    }
  }
  & + .bnrBox {
    margin-top: clamp(-130px, vw(-130), -130px);
  }
}
.bnrBox {
  padding: 0 1%;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    width: calc((100% - 40px) / 2);
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 5px 25px rgba(34, 34, 34, .05);
    padding: 30px 55px 40px;
    &:nth-child(2n) {
      margin-left: 40px;
    }
    &.yellow {
      .bnrBox__link {
        &::before {
          background: #776231;
        }
      }
      .bnrBox__img {
        &::after {
          background: url(../img/top/top_bnr_arrow02_pc.png) no-repeat center center;
          background-size: contain;
        }
      }
    }
    @include mq(pc) {
      &:hover {
        box-shadow: 0 5px 25px rgba(34, 34, 34, .2);
        .bnrBox__link {
          text-decoration: underline;
        }
        .bnrBox__img {
          &::after {
            right: -43px;
          }
        }
      }
    }
  }
  &__link {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.66666;
    color: $font-color-base;
    text-decoration: none;
    position: relative;
    &::before {
      content: "";
      width: 12px;
      height: 12px;
      display: inline-block;
      border-radius: 50px;
      background: $color-theme;
      position: absolute;
      top: 12px;
      left: -27px;
    }
  }
  &__img {
    position: relative;
    &::after {
      content: "";
      width: 76px;
      height: 76px;
      display: inline-block;
      background: url(../img/top/top_bnr_arrow01_pc.png) no-repeat center center;
      background-size: contain;
      position: absolute;
      top: calc(50% + 15px);
      right: -38px;
      transform: translateY(-50%);
    }
  }
  &__text {
    font-size: 1.6rem;
    line-height: 1.75;
    margin: 17px 0 0;
  }
}


@media screen and (max-width: $display-width-s) {
  /*=================================
    topPage
  =================================*/
  .home {
    .secWrap {
      padding: 60px per(17);
      &.reason { /* 選ばれる理由 */
        padding: 60px 0 60px per(17);
        background: url(../img/top/bg_reason.svg) no-repeat right -80px top 76px;
        background-size: 260px auto;
        .reason__inner {
          padding: 40px per(40) 40px per(24);
          border-radius: 15px 0 0 15px;
        }
        .reasonList {
          display: block;
          &__item {
            width: 100%;
            border-radius: 7px;
            padding: 22px per(10);
            display: block;
            &:not(:nth-child(3n + 1)) {
              margin: 20px 0 0;
            }
            .icon {
              width: 23%;
              margin: 0 auto 15px;
            }
            .text {
              font-size: 2rem;
              &.sm {
                font-size: 2rem;
              }
            }
            .numWrap {
              font-size: 2rem;
              display: inline-block;
              .num {
                font-size: 2.5rem;
                line-height: 1.11428;
                border-bottom: 3px solid $color-theme;
                &:not(.sm) {
                  font-size: 3.5rem;
                }
              }
            }
          }
        }
      }
      &.faq {
        padding: 60px 0;
        background: #fff;
        .secWrap__inner {
          padding: 0;
          .ttl--base,.ttl--text {
            padding: 0 per(40);
          }
        }
        .faqWrap {
          margin-top: 30px;
          background: url(../img/top/bg_qa_sp.png) no-repeat right top;
          background-size: calc(100% - per(40)) auto;
          padding: 40px per(17) 60px 0;
        }
        .faqList {
          padding: 28px per(60) 28px per(18);
          border-radius: 0 7px 7px 0;
          .q {
            font-size: 1.8rem;
            font-weight: 700;
            padding-left: 22px;
            &::before {
              font-size: 1.8rem;
              top: 0;
            }
            &::after {
              width: 36px;
              height: 36px;
              background: url(../img/common/icon/icon_qa_open_sp.png) no-repeat center center;
              background-size: contain;
              right: per(-60);
            }
            &.open {
              &::after {
                background: url(../img/common/icon/icon_qa_close_sp.png) no-repeat center center;
                background-size: contain;
              }
            }
          }
          .a {
            font-size: 1.4rem;
            line-height: 1.857142;
            padding: 13px 0 0 22px;
            * + p {
              margin-top: 22px;
            }
            a {
              font-weight: 500;
            }
          }
          & + .faqList {
            margin-top: 20px;
          }
        }
      }
    }
    .media {
      &__ttl {
        background: url(../img/top/bg_media_sp.jpg) no-repeat center center;
        background-size: cover;
        padding: 40px per(40);
        .ttl {
          font-size: 2.4rem;
          line-height: 1;
          &::before {
            width: 8px;
            height: 8px;
            margin: -5px 9px 0 0;
          }
        }
        .text {
          font-size: 1.4rem;
          line-height: 1.85714;
          text-align: left;
        }
      }
      &Wrap {
        padding: 24px per(27) 40px;
      }
      &List {
        &__item {
          width: calc((100% - per(16)) / 2);
          margin-top: 16px;
          img {
            &:not(.vertical) {
              width: 80%;
            }
            &.vertical {
              width: auto;
              height: 90px;
            }
          }
          &:not(:nth-child(6n + 1)) {
            margin-left: 0;
          }
          &:nth-child(2n) {
            margin-left: per(16);
          }
        }
      }
    }
    .relatedBox {
      background: url(../img/under/bg_related_blue.png) no-repeat right -90px top 0;
      padding: 0px per(40) 30px;
    }
    .access {
      background: url(../img/top/bg_access.png) no-repeat right -60px top 0;
      padding: 0 per(40) 60px;
      &__inner {
        padding: 0;
      }
      &Cont {
        display: block;
      }
      &__text {
        margin: 0 0 30px;
      }
      &__gMap {
        width: 100%;
        min-width: initial;
        padding-top: 94.2%;
        border-radius: 15px;
      }
    }
  }
  /* メインビジュアル */
  .mv {
    background: url(../img/top/mv_bg_sp.jpg) no-repeat center top;
    background-size: cover;
    min-height: 650px;
    padding: 0 per(40) 0 per(17);
    &__catch {
      font-size: 2.8rem;
      line-height: 1.78571;
      padding: clamp(100px, vw(115,$sp-width), 115px) 0 0;
    }
    & + .bnrBox {
      margin-top: -264px;
    }
  }
  .bnrBox {
    padding: 0 per(17);
    &__inner {
      display: block;
    }
    &__item {
      width: 100%;
      border-radius: 10px;
      padding: 30px per(23);
      margin-bottom: 20px;
      &:nth-child(2n) {
        margin-left: 0;
      }
    }
    &__link {
      font-size: 2rem;
      line-height: 1.6;
      padding-left: 16px;
      &::before {
        width: 8px;
        height: 8px;
        left: 0px;
      }
    }
    &__img {
      &::after {
        width: 36px;
        height: 36px;
        top: calc(50% + 10px);
        right: -18px;
      }
    }
    &__text {
      font-size: 1.6rem;
      line-height: 1.75;
      margin: 17px 0 0;
    }
  }
}